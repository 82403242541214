export const formatNumber = (number) => {
    if (Number(number.value) <= 999) {
      return Number(number.value.toString());
    }
    const formattedNumber = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 20
    }).format(number.value);
    return formattedNumber.replace('.', ','); // Replace dot with comma if necessary
  };
  