import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    color: "#E0E0E0", // Light gray text for contrast
    backgroundColor: "#1C1C1C", // Dark background matching your theme
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <div>
        <h1 style={{ fontSize: "3rem", marginBottom: "20px" }}>
          404 - Page Not Found
        </h1>
        <p style={{ fontSize: "1.2rem" }}>
          Sorry, the page you are looking for does not exist.
        </p>
        <button
          onClick={handleGoBack}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#2E2E2E", // Darker button background
            color: "#E0E0E0", // Light text for the button
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
