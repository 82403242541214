import React, { useEffect, useState, useCallback } from "react";
import { Grid, IconButton, ThemeProvider } from "@material-ui/core";
import { Header } from "../components/Header";
import { TabManager } from "../components/TabsManager";
import { theme } from "../style/style";
import { tabsList } from "../utils/tabsList";
import { TabContent } from "../components/TabContent";
import { closeWS, connectWS, sendEvent, ws } from "../../../services/websocket";
import * as rfqSlice from "../../../store/rfq/rfqSlice";
import { useDispatch } from "react-redux";

export const CboeCross = () => {
  const dispatch = useDispatch();
  const isTradeTypeEAlreadyChose = sessionStorage.getItem("cboeTradeType")
  const token = sessionStorage.getItem("token");

  const [orderBook, setOrderBook] = useState("BXE");
  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState(rowData);
  const [tradeType, setTradeType] = useState(isTradeTypeEAlreadyChose === "null" ? true : isTradeTypeEAlreadyChose === "EFP" ? true : false)
  const [selectedTab, setSelectedTab] = useState(
    tabsList[0]?.value || "workspace",
  );

  const filterData = rowData.filter((row) => {
    return row?.venue?.toLowerCase() === orderBook?.toLowerCase()
  })

  const updateTradeStatus = useCallback(
    async (updateBody) => {
      setRowData((pre) => {
        const arr = [...pre];
        const index = arr.findIndex(
          (row) => row.tradeId === updateBody.tradeId,
        );
        if (index !== -1) {
          arr.splice(index, 1, { ...arr[index], ...updateBody });
        }
        return arr;
      });
    },
    [rowData],
  );
 
  useEffect(() => {
    if (ws !== null) {
      sendEvent({
        type: "cboe",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);

      sendEvent({
        type: "cboe",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    }

    return () => {
      sendEvent({
        type: "unsubscribe_cboe",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    };
  }, []);

  useEffect(() => {
    const handleCboe = async (message) => {
      switch (message.data.type) {
        case "update_cboe_trade":
          updateTradeStatus(message.data.updateBody);
          break;

        default:
          break;
      }
    };

    window.addEventListener("message", handleCboe);

    return () => {
      window.removeEventListener("message", handleCboe);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        style={{
          color: "white",
        }}
      >
        <Header orderBook={orderBook} setOrderBook={setOrderBook} setRowData={setRowData} setFilteredData={setFilteredData}/>
        <Grid item xs={12} style={{display:"flex"}}>
          <TabManager
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Grid>
        <TabContent
          selectedTab={selectedTab}
          orderBook={orderBook}
          rowData={filterData}
          setRowData={setRowData}
          tradeType={tradeType}
          setTradeType={setTradeType}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
        />
      </Grid>
    </ThemeProvider>
  );
};
