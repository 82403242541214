export const dateFormatter = (params) => {
  const inputDate = params.value;

  if (inputDate) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const dateObj = new Date(inputDate);
    const year = dateObj.getFullYear();
    const month = months[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0'); // Ensure two-digit day

    const convertedDate = `${day}-${month}-${year}`;
    return convertedDate;
  } else {
    return undefined;
  }
};
