import React, { useState } from "react";
import { Grid, Typography, Box, Switch } from "@material-ui/core";
import { useStyles } from "../style/style";

export const Header = ({ orderBook, setOrderBook,setRowData,setFilteredData}) => {
  const [orderBookSwitcher, setOrderBookSwitcher] = useState(
    orderBook === "BXE" ? false : true,
  );
  const classes = useStyles({ orderBook, orderBookSwitcher });

  const handleSwitchOrderBook = (e) => {
    const isChecked = e.target.checked; // Access the boolean value from e.target.checked
    setOrderBookSwitcher(isChecked);
    isChecked ? setOrderBook("DXE") : setOrderBook("BXE");
    setRowData([])
    setFilteredData([])
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      style={{ marginTop: 22,marginBottom:18 }}
    >
      <Typography variant="h4">CBOE Cross</Typography>
      <Typography variant="h4" className={classes.orderBookTypography}>
        {orderBook}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ gap: "8px" }} // Adds space between the labels and the switch
      >
        {" "}
        <Typography
          variant="h5"
          style={{
            color: !orderBookSwitcher ? "#FFFFFF" : "#989EA8", // Highlight the selected label
            fontWeight: !orderBookSwitcher ? "bold" : "normal", // Bold text for the active label
          }}
        >
          UK
        </Typography>
        <Switch
          checked={orderBookSwitcher}
          onChange={handleSwitchOrderBook}
          color="primary"
          name="checkedB"
          inputProps={{ "aria-label": "primary checkbox" }}
          className={classes.customSwitch}
        />
        <Typography
          variant="h5"
          style={{
            color: orderBookSwitcher ? "#FFFFFF" : "#989EA8", // Highlight the selected label
            fontWeight: orderBookSwitcher ? "bold" : "normal", // Bold text for the active label
          }}
        >
          EU
        </Typography>
      </Box>
    </Grid>
  );
};
