import React, { useEffect, useState } from "react";
import { fetchParticipants } from "../../api/apiCalls";
import { GenericTable } from "../genericTable/GenericTable";
import {
  Box,
  Grid,
  InputBase,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "../../style/style";
import moment from "moment";
import { ReactComponent as DownloadCsv } from "../../../../assets/csv-download.svg";
import SearchIcon from "@material-ui/icons/Search";

export const BatsParticipants = ({ orderBook ,selectedTab }) => {
  const [participantsList, setParticipantsList] = useState(null);
  const columnDefs = [
    { headerName: "CBOE Participant Code", field: "allegedFirmBankCode" },
    { headerName: "Participant Name", field: "allegedFirm" },
    { headerName: "Participant MPID", field: "allegedFirmMpid" },
    { headerName: "LEI", field: "allegedFirmLei" },
  ];
  const [search, setSearch] = useState("");
  const classes = useStyles({});

  const handleDownload = async () => {
    try {
      const response = await fetchParticipants(orderBook, 0, 100000, search);
      downloadJSONAsCSV(response.rows);
    } catch (error) {
      console.log(error);
    }
  };
 const downloadJSONAsCSV = async (rowData) => {
    // id,tardeI
 if (typeof selectedTab !== "string") {
      console.error("Expected `selectedTab` to be a string, got:", selectedTab);
      return;
    }
    const formattedDate = moment().format("YYYYMMDD");
    const filename = `${orderBook}_ParticipantData_${formattedDate}.csv`; // Combine selectedTab and date for filename

    // Convert JSON data to an array of arrays
    const keys = Object.keys(rowData[0]); // Get the headers from the first object
    const csvRows = [keys.join(",")]; // Join headers with commas

    // Add data rows
    rowData.forEach((item) => {
      const values = keys.map((key) => {
        const value = item[key];
        return typeof value === "string"
          ? `"${value.replace(/"/g, '""')}"`
          : value; // Escape double quotes
      });
      csvRows.push(values.join(","));
    });

    // Create a Blob from the CSV rows
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element for download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
             <Grid style={{ display: "flex", gap: "10px" }}>
              <Grid>
                <InputAdornment>
                  <IconButton onClick={handleDownload}>
                    <DownloadCsv className={classes.csvIcon} />
                  </IconButton>
                </InputAdornment>
              </Grid>
              <Grid>
                <InputBase
                  className={classes.searchInput}
                  variant="outlined"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
      </Grid>
      <GenericTable
        columnDefs={columnDefs}
        isParticipants={true}
        orderBook={orderBook}
        search={search}
      />
    </>
  );
};
