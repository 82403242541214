const clientList = ["RBS BOA", "O RBS BOA", "HSBC-BOA"];
const markerMakerList = ["RBS-BOA", "RBS-O BOA", "HSBC-BOA"];

export const clientVerifier = (clientsArray) => {
  const hasClientMatch = clientsArray.some((value) =>
    clientList.includes(value),
  );

  const hasMarkerMakerMatch = clientsArray.some((value) =>
    markerMakerList.includes(value?.replace(" (MM)", "")),
  );
  return hasClientMatch || hasMarkerMakerMatch;
};
