import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { END_POINT } from "../../utils/index";
import { clearLoginState } from "../users/usersSlice";
import * as postTradeActions from "./rfqPostTradeSlice";
import * as actionSnackBar from "../snackbar/action";

export const getCompleteClientList = () => async (dispatch, getState) => {
  const token = sessionStorage.getItem("token");
  try {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_BASE_URL}` +
    //     END_POINT.MARKET_MAKER +
    //     "/get_market_makers_and_clients",
    //   { headers: { Authorization: token } },
    // );
  
    const response = await axios.get(process.env.REACT_APP_FXER_URL, {
      headers: {
        "x-api-key": "27cJJy5IhWhYra/p!WaFVbODYAaw2yyo?A4tVG4WGJu?sW3YRPyT!J?kfmdFpYNGtppAOd45fACfAyoxAPG3YHyFsjelkyHvAZ0fPzUDt?g5S3aWqdyPeh3qp?atyiJLe"
      },
    })
 
    const data = response.data.data
    const clientList = [];
     data.makorClients?.map((c) => {
      clientList.push({
        id: c.clientId,
        value: `${c.shortname} ${c.pm_name === null ? "" : c.pm_name}`,
      });
    });
    data.oscarGrussClients?.map((c) => {
      clientList.push({
        id: c.clientId,
        value: `${c.shortname} ${c.pm_name === null ? "" : c.pm_name}`,
      });
    });
    data.makorHSBCClients?.map((c) => {
      clientList.push({
        id: c.clientId,
        value: `${c.shortname} ${c.pm_name === null ? "" : c.pm_name}`,
      });
    });
    dispatch(setClientListSuccess(clientList));
  } catch (err) {
    console.log(err);
  }
};
export const getRfq =
  (search = "", date = "") =>
  async (dispatch, getState) => {
    const currentRfq = getState().rfqSlice.rfq;
    const token = sessionStorage.getItem("token");

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.RFQ +
          `/?search=${search}&date=${date}`,
        { headers: { Authorization: token } },
      );

      let rfqData;
      if (search === "" && date === "") {
        if (currentRfq.length === 0) {
          rfqData = res.data.map((element) => {
            return { ...element, checked: false };
          });
        } else if (currentRfq.length < res.data.length) {
          const rfqTotal = [...currentRfq];
          let rfqFlag;
          res.data.forEach((resRfqData) => {
            rfqFlag = currentRfq.find(
              (rfq) => rfq.rfq_id === resRfqData.rfq_id,
            );
            if (rfqFlag === undefined) {
              resRfqData.checked = false;
              rfqTotal.push(resRfqData);
            }
          });
          rfqData = rfqTotal;
        } else if (currentRfq.length === res.data.length) {
          rfqData = currentRfq;
        } else if (currentRfq.length > res.data.length) {
          rfqData = res.data;
        }
      } else {
        rfqData = res.data.map((element) => {
          return { ...element, checked: false };
        });
      }

      dispatch(getRfqSuccess(rfqData));
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(clearLoginState());
      }
    }
  };
export const getRfqById = (id) => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.RFQ +
        `/rfq_details/${id}`,
      { headers: { Authorization: token } },
    );
    sessionStorage.setItem("rfqId", id);
    sessionStorage.setItem("rfqDealType", res.data.rfq[0].deal_type_text);
    sessionStorage.setItem("rfqStatus", res.data.rfq[0].status);
    sessionStorage.setItem("rfqTraderId", res.data.rfq[0].trader_id);

    let status = sessionStorage.getItem("status");
    if (status !== "copy" && res.data.rfq[0].booked_trade_obj_values !== null) {
      dispatch(
        updateRfqBookingModeUpdatingRows(
          res.data.rfq[0].booked_trade_obj_values.bookingModeData,
        ),
      );
    }

    dispatch(updateCreatedRfqDetails(res.data.rfq));
    if (status !== "copy") {
      dispatch(updateRequestFor(res.data.rfq[0].client_id));
      dispatch(postTradeActions.setAllValuesIfConfosExist(res.data.rfqEmail));
    }
    dispatch(getRfqByIdSuccess(res.data.rfq));

    return res.data;
  } catch (error) {
    console.log(error);
    if (error.response.status === 401) {
      dispatch(clearLoginState());
      dispatch(getRfqByIdFailed());
    }
    dispatch(getRfqByIdFailed());
  }
};
export const getCheckedRfq = (id) => async (dispatch, getState) => {
  const token = sessionStorage.getItem("token");
  const rfqButtonStatus = getState().rfqSlice.rfqButtonStatus;
  const status = sessionStorage.getItem("status");

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.RFQ +
        `/rfq_details/${id}`,
      { headers: { Authorization: token } },
    );

    sessionStorage.setItem("rfqId", id);
    sessionStorage.setItem("rfqDealType", res.data.rfq[0].deal_type_text);
    sessionStorage.setItem("rfqStatus", res.data.rfq[0].status);
    sessionStorage.setItem("rfqTraderId", res.data.rfq[0].trader_id);
    let newRfq = {
      rfq_description: res.data.rfq[0].rfq_description,
      id: res.data.rfq[0].rfq_id,
    };
    let status = sessionStorage.getItem("status");

    if (rfqButtonStatus !== "copy") {
      const obj = {
        tab: newRfq,
        userId: sessionStorage.getItem("id"),
        action: "add tab",
        tabIsChosen: true,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.UPDATE_TABS,
        obj,
        { headers: { Authorization: token } },
      );
      dispatch(updatRfqInstanceList(res.data));

      dispatch(postTradeActions.setAllValuesIfConfosExist(res.data.rfqEmail));
    }
    if (status !== "copy" && res.data.rfq[0].booked_trade_obj_values !== null) {
      dispatch(
        updateRfqBookingModeUpdatingRows(
          res.data.rfq[0].booked_trade_obj_values.bookingModeData,
        ),
      );
    }
    if (status !== "copy") {
      dispatch(updateRequestFor(res.data.rfq[0].client_id));
    }

    dispatch(getRfqCheckedSuccess(res.data.rfq));
  } catch (error) {
    console.log(error);

    if (error.response.status === 401) {
      dispatch(clearLoginState());
      dispatch(getRfqByIdFailed());
    }
    dispatch(getRfqByIdFailed());
  }
  //loading true
};
export const getCurrencyPairs = () => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    dispatch(startCurrencyPairsLoading());
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` + END_POINT.CURRENCY,
      {
        headers: { Authorization: token },
      },
    );
    const ccyPairs = response.data.map((i) => {
      return { value: i.ccy_pair, text: i.ccy_pair, id: i.id, cut: i.cut };
    });
    dispatch(getCurrencyPairsSuccess(ccyPairs));
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(clearLoginState());
    }
  }
};
export const getSingleCurrencies = () => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.CURRENCY}/single_currencies`,
      {
        headers: { Authorization: token },
      },
    );
    const singleCurrencies = response.data.map((i) => {
      return { value: i.currency, text: i.currency, id: i.id };
    });
    dispatch(getSingleCurrenciesSuccess(singleCurrencies));
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(clearLoginState());
    }
  }
};
export const getPbAccounts = () => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    dispatch(startPbAccountLoading());
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` + END_POINT.PB_ACCOUNT,
      {
        headers: { Authorization: token },
      },
    );
    const pbAccountList = response.data.map((i) => {
      return { id: i.id, name: i.pb_account };
    });
    dispatch(getPbAccountSuccess(pbAccountList));
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(clearLoginState());
    }
  }
};
export const getDealType = () => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    dispatch(startPbAccountLoading());
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` + END_POINT.DEAL_TYPE,
      {
        headers: { Authorization: token },
      },
    );

    dispatch(getDealTypesSuccess(response.data));
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(clearLoginState());
    }
  }
};
export const getClients = () => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    dispatch(startPbAccountLoading());
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` + END_POINT.CLIENTS,
      {
        headers: { Authorization: token },
      },
    );
    const allClients = response.data.map((client) => {
      return { id: client.id, value: client.name, text: client.name };
    });
    dispatch(getClientsSuccess(allClients));
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(clearLoginState());
    }
  }
};
const copyState = (state) => {
  return JSON.parse(JSON.stringify(state));
};
export let rfqSlice = createSlice({
  name: "rfqSlice",
  initialState: {
    rfq: [],
    traderNote: "",
    notification: [],
    hidden_keys: [
      "OPTION_TYPE",
      "BARRIER",
      "BARRIER2",
      "FIXING_REF",
      "CCYPAIR_GROUP",
      "BARRIER_START_DATE",
      "BARRIER_END_DATE",
      "NEAR_FIXING_DATE",
      "FAR_FIXING_DATE",
      "SPOT_REF",
      "INCLUDE_REF",
      "NEAR_SWAP",
      "FAR_SWAP",
      "FIXING_REF",
      "REQUEST_FOR",
    ],
    cross_list_data: { items: [], isLoading: true },
    singleCurrenciesList: { items: [], isLoading: true },
    pb_accounts: { items: [], isLoading: true },
    dealTypes: { items: [], isLoading: true },
    clients: { items: [], isLoading: true },
    clientsList: { items: [], isLoading: true },
    rfqButtonStatus: "",
    rfqId: { items: [], isLoading: true },
    rfqInstanceList: [],
    rfqCreatedDetails: [{ created_on: "", traderName: "" }],
    rfqMode: "",
    rfqObject: {
      rfq_description: null,
      launch_status: false,
      rfq_table_data: null,
      aggregator_mode: false,
      booking_mode: false,
      quote_type: "",
      rfq_str: "",
      ovml_tail: "",
      order_book: null,
      quote_parameters: null,
      quote_book: 1,
      test: null,
      deal_stats: null,
      rfq_id: "",
    },
    tableData: [],
    dealStatsData: {
      pricing_leg_notional: "---",
      fees: "---",
      fees_pct_notional: "---",
      fees_vol: "---",
      vol_point: "---",
      vol_point_pct_notional: "---",
      raw_bid_pnl: "---",
      raw_offer_pnl: "---",
      market_offer: "---",
      client_price_string: null,
      makor_bid: "---",
      makor_offer: "---",
      net_bid_pnl: "---",
    },
    rfqStr: "",
    ovmlTail: "",
    rfqObjectUpdate: false,
    highlightedRfqTab: "",
    rfqBookingModeUpdatingRows: [],
    lpPerdiction: [],
    socketStatus: "error",
    isNavigateToRfqBlotter: false,
    rfqLocationMode: [],
    statusItem: "",
    statusCtrlEnter: "",
    requestFor: "",
    isRfqStatusUpdateLive: false,
    requestFor: "",
    recommendationRfqs: [],
  },

  reducers: {
    updateIsRfqStatusUpdateLive: (state, action) => {
      state.isRfqStatusUpdateLive = action.payload;
    },
    updateRecommendationRfqs: (state, action) => {
      state.recommendationRfqs = action.payload;
    },
    updateRequestFor: (state, action) => {
      state.requestFor = action.payload;
    },
    setStatusItem: (state, action) => {
      state.statusItem = action.payload;
    },
    setStatusCtrlEnter: (state, action) => {
      state.statusCtrlEnter = action.payload;
    },
    updateRfqLocationMode: (state, action) => {
      const copyRfqLocationArray = state.rfqLocationMode;
      let rfqIndex = copyRfqLocationArray.findIndex((key) => {
        return Number(key.rfq_id) === Number(action.payload.rfq_id);
      });
      if (rfqIndex !== -1) {
        copyRfqLocationArray.splice(rfqIndex, 1, action.payload);
      } else {
        copyRfqLocationArray.push(action.payload);
      }
      state.rfqLocationMode = copyRfqLocationArray;
    },
    setIsNavigateToRfqBlotter: (state, action) => {
      state.isNavigateToRfqBlotter = action.payload;
    },
    updateSocketStatus: (state, action) => {
      state.socketStatus = action.payload;
    },
    updateLpPerdiction: (state, action) => {
      state.lpPerdiction = action.payload;
    },
    updateRfqBookingModeUpdatingRows: (state, action) => {
      state.rfqBookingModeUpdatingRows = action.payload;
    },
    updateHighlightedRfqTab: (state, action) => {
      state.highlightedRfqTab = action.payload;
    },
    clearRfqState: (state, action) => {
      state.requestFor = "";
      state.rfqMode = "";
      state.rfqButtonStatus = "";
      state.rfqObjectUpdate = false;
      state.rfqId.items = [];
      state.rfq = [];
      state.rfqCreatedDetails = [{ created_on: "", traderName: "" }];
      state.tableData = [];
      state.dealStatsData = {
        pricing_leg_notional: "---",
        fees: "---",
        fees_pct_notional: "---",
        fees_vol: "---",
        vol_point: "---",
        vol_point_pct_notional: "---",
        raw_bid_pnl: "---",
        raw_offer_pnl: "---",
        makor_bid: "---",
        makor_offer: "---",
      };
      state.rfqStr = "";
      state.ovmlTail = "";
      state.rfqBookingModeUpdatingRows = [];
      // sessionStorage.setItem("rfqMode", "");
      sessionStorage.removeItem("rfqId", "");
      state.hidden_keys = [
        "OPTION_TYPE",
        "BARRIER",
        "BARRIER2",
        "FIXING_REF",
        "CCYPAIR_GROUP",
        "NEAR_SWAP",
        "FAR_SWAP",
        "NEAR_DATE_TEXT",
        "FAR_DATE_TEXT",
        "NEAR_NOTIONAL",
        "FAR_NOTIONAL",
        "SWAP_CCY",
        "NEAR_DATE",
        "FAR_DATE",
        "DATES_IN_RFQ",
        "SWAP_DIRECTION",
        "CLIENT_START_RATE",
        "MARKET_START_RATE",
        "CLIENT_SWAP_PTS",
        "MARKET_SWAP_PTS",
        "CLIENT_FAR_RATE",
        "MARKET_FAR_RATE",
      ];
    },
    updateRqObjectUpdate: (state, action) => {
      state.rfqObjectUpdate = action.payload;
    },
    clearRqObjectUpdate: (state, action) => {
      state.rfqObjectUpdate = action.payload;
    },
    updateRfqStr: (state, action) => {
      state.rfqStr = action.payload;
    },
    updateOvmlTail: (state, action) => {
      state.ovmlTail = action.payload;
    },
    clearDealStatsData: (state, action) => {
      state.dealStatsData = {
        pricing_leg_notional: "---",
        fees: "---",
        fees_pct_notional: "---",
        fees_vol: "---",
        vol_point: "---",
        vol_point_pct_notional: "---",
        raw_bid_pnl: "---",
        raw_offer_pnl: "---",
        makor_bid: "---",
        makor_offer: "---",
      };
    },
    updateDealStatsData: (state, action) => {
      state.dealStatsData = action.payload;
    },
    updateTableData: (state, action) => {
      state.tableData = action.payload;
    },
    updateRfqObject: (state, action) => {
      state.rfqObject = action.payload;
    },

    clearCreatedRfqDetails: (state, action) => {
      state.rfqCreatedDetails = [{ created_on: "", traderName: "" }];
    },
    clearRfq: (state, action) => {
      state.rfq = [];
    },
    clearrfqButtonStatus: (state, action) => {
      state.rfqButtonStatus = "";
    },
    updateCreatedRfqDetails: (state, action) => {
      state.rfqCreatedDetails[0].created_on = action.payload[0].created_on;
      state.rfqCreatedDetails[0].traderName = action.payload[0].userName;
    },
    addRfqInstanceList: (state, action) => {
      const copy = state.rfqInstanceList;
      if (!copy.find((o) => Number(o.id) === Number(action.payload.id)))
        copy.unshift(action.payload);
      state.rfqInstanceList = copy;
    },
    updatRfqInstanceList: (state, action) => {
      state.rfqInstanceList = action.payload;
    },
    clearRfqId: (state, action) => {
      state.rfqId.items = [];
    },
    clearrfqInstanceList: (state, action) => {
      state.rfqInstanceList = [{ rfq_description: "NEW RFQ" }];
    },
    updatRfqButtonStatus: (state, action) => {
      state.rfqButtonStatus = action.payload;
    },
    clearRfqButtonStatus: (state, action) => {
      state.rfqButtonStatus = "";
    },
    updatTraderNote: (state, action) => {
      state.traderNote = action.payload;
    },
    swapTradeNote: (state, action) => {
      const rfqId = action.payload.rfqId;
      const note = action.payload.rfqNote;
      const trade = state.rfq.find((rfq) => rfq.rfq_id === Number(rfqId));
      trade.trader_notes = note;
    },
    clearTraderNote: (state, action) => {
      state.traderNote = "";
    },
    addHidenKey: (state, action) => {
      const copyHiddenKeysArray = Array.from(new Set(state.hidden_keys));
      copyHiddenKeysArray.push(action.payload);
      state.hidden_keys = copyHiddenKeysArray;
    },
    removeHidenKey: (state, action) => {
      const findKeyIndex = state.hidden_keys.findIndex((key) => {
        return key === action.payload;
      });
      if (findKeyIndex >= 0) {
        const copyHiddenKeyArray = Array.from(new Set(state.hidden_keys));
        copyHiddenKeyArray.splice(findKeyIndex, 1);
        state.hidden_keys = copyHiddenKeyArray;
      }
    },
    restartNotificationAcount: (state, action) => {
      state.notification = [];
    },
    controleNotificationAcount: (state, action) => {
      const copyNotificationArray = Array.from(new Set(state.notification));
      copyNotificationArray.push(action.payload);
      state.notification = copyNotificationArray;
    },
    getRfqSuccess: (state, action) => {
      state.rfq = action.payload;
    },
    updateRfqSuccess: (state, action) => {
      state.rfq = action.payload;
    },
    changeStatusChecked: (state, action) => {
      state.rfq = action.payload;
    },
    addNewRfqFromWebSocketToRfqArray: (state, action) => {
      const rfqExist = state.rfq.find(
        (rfq) => Number(rfq.rfq_id) === Number(action.payload.rfq_id),
      );

      if (rfqExist === undefined) {
        state.rfq.unshift(action.payload);
      } else {
      }
    },
    idOfRfq: (state, action) => {
      const find = state.rfq.map((i) => {
        if (i.rfq_id === action.payload) {
          i.color = false;
        }
        return i;
      });
      state.rfq = find;
    },
    startCurrencyPairsLoading: (state) => {
      state.cross_list_data.isLoading = true;
    },
    getCurrencyPairsSuccess: (state, action) => {
      state.cross_list_data.items = action.payload;
      state.cross_list_data.isLoading = false;
    },
    getSingleCurrenciesSuccess: (state, action) => {
      state.singleCurrenciesList.items = action.payload;
      state.singleCurrenciesList.isLoading = false;
    },
    startPbAccountLoading: (state) => {
      state.pb_accounts.isLoading = true;
    },
    getPbAccountSuccess: (state, action) => {
      state.pb_accounts.items = action.payload;
      state.pb_accounts.isLoading = false;
    },
    getDealTypesSuccess: (state, action) => {
      state.dealTypes.items = action.payload;
      state.dealTypes.isLoading = false;
    },
    getClientsSuccess: (state, action) => {
      state.clients.items = action.payload;
      state.clients.isLoading = false;
    },
    setRfqIsLoading: (state, action) => {
      state.rfqId.isLoading = true;
    },
    getRfqByIdSuccess: (state, action) => {
      let copyRfqId = state.rfqId;
      copyRfqId.items = action.payload;
      state.rfqId = copyRfqId;
      state.rfqId.isLoading = false;
    },
    getRfqCheckedSuccess: (state, action) => {
      state.rfqId.items = action.payload;
    },
    setClientListSuccess: (state, action) => {
      state.clientsList.items = action.payload;
      state.clientsList.isLoading = false;
    },
    getRfqByIdFailed: (state, action) => {
      state.rfqId.items = [];
      state.rfqId.isLoading = false;
    },
  },
});

export const updateLocalRfq = (dataObj) => async (dispatch, getState) => {
  const { rfq_id, status, data } = dataObj;
  const rfqsList = getState().rfqSlice.rfq;

  const arr = [...rfqsList];
  let rfqIndex = arr.findIndex((key) => {
    return Number(key.rfq_id) === Number(rfq_id);
  });
  let rfqObj = { ...arr[rfqIndex] };

  rfqObj = {
    ...rfqObj,
    rfq_id: rfq_id,
    status: status,
    post_trade_stats: data,
  };
  arr.splice(rfqIndex, 1, rfqObj);
  dispatch(getRfqSuccess(arr));
  dispatch(setStatusItem(status));
};
export const updateBookingDetails = (data) => async (dispatch, getState) => {
  // const rfqBookingModeUpdatingRows =
  //   getState().rfqSlice.rfqBookingModeUpdatingRows;
  // const copyRfqBookingModeUpdatingRows = [...rfqBookingModeUpdatingRows];
  // const rfq = { ...data };
  // const flag = copyRfqBookingModeUpdatingRows.findIndex(
  //   ({ rfqId }) => Number(rfqId) === Number(rfq.rfqId),
  // );
  // if (flag !== -1) {
  //   copyRfqBookingModeUpdatingRows.splice(flag, 1, rfq);
  // } else {
  //   copyRfqBookingModeUpdatingRows.push(rfq);
  // }
  // dispatch(updateRfqBookingModeUpdatingRows(copyRfqBookingModeUpdatingRows));
};

export const updateRfqStatus =
  (rfqData, type) => async (dispatch, getState) => {
    const rfqsList = getState().rfqSlice.rfq;
    const rfqId = getState().rfqSlice.rfqId;
    const arr = [...rfqsList];

    let rfqIndex = arr.findIndex((key) => {
      if (rfqData !== undefined) {
        return Number(key.rfq_id) === Number(rfqData.rfq_id);
      }
    });

    let rfqObj = { ...rfqsList[rfqIndex] };
    rfqObj = {
      ...rfqObj,
      status: rfqData.status,
      post_trade_stats: rfqData.data,
    };

    arr.splice(rfqIndex, 1, rfqObj);
    dispatch(getRfqSuccess(arr));
    dispatch(setStatusItem(rfqData.status));
    if (Number(rfqData?.rfq_id) === Number(rfqId.items[0]?.rfq_id)) {
      let copyRfqId = { ...rfqId.items[0] };
      copyRfqId = {
        ...copyRfqId,
        status: rfqData.status,
      };
      // dispatch(getRfqByIdSuccess([copyRfqId]));
    }
  };

export const updateRfqHandler =
  (updatedRfq, navigate) => async (dispatch, getState) => {
    const rfqsList = getState().rfqSlice.rfq;
    const rfqInstanceList = getState().rfqSlice.rfqInstanceList;
    const rfqsListCopy = [...rfqsList];
    const rfqInstanceListCopy = [...rfqInstanceList];
    const rfqIndex = rfqsList.findIndex(
      (rfq) => Number(rfq.rfq_id) === Number(updatedRfq[0].rfq_id),
    );
    if (rfqIndex !== -1) {
      if (updatedRfq[0].status !== "Deleted") {
        rfqsListCopy.splice(rfqIndex, 1, updatedRfq[0]);
        dispatch(getRfqSuccess(rfqsListCopy));
        const rfqIdFromStorage = sessionStorage.getItem("rfqId");
        if (Number(rfqIdFromStorage) === Number(updatedRfq[0].rfq_id)) {
          dispatch(setStatusItem(updatedRfq.status));
        }
      } else if (updatedRfq[0].status === "Deleted") {
        rfqsListCopy.splice(rfqIndex, 1);
        dispatch(clearRfqState());
        dispatch(getRfqSuccess(rfqsListCopy));

        const rfqTabIndex = rfqInstanceList.findIndex(
          (tab) => Number(tab.id) === Number(updatedRfq[0].rfq_id),
        );
        if (rfqTabIndex !== -1) {
          const objToRemove = {
            tab: rfqInstanceListCopy[rfqTabIndex],
            userId: sessionStorage.getItem("id"),
            action: "remove tab",
          };
          try {
            if (
              rfqInstanceListCopy[rfqTabIndex].tabIsChosen === true &&
              window.location.pathname.includes("rfqworkspace")
            ) {
              navigate("/rfqblotter");
              dispatch(
                actionSnackBar.setSnackBar("error", "The RFQ has been deleted"),
              );
            }
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}` + END_POINT.UPDATE_TABS,
              objToRemove,
              { headers: { Authorization: sessionStorage.getItem("token") } },
            );

            dispatch(updatRfqInstanceList(res.data));
          } catch (error) {}
        }
      }
    } else if (rfqIndex === -1) {
      if (updatedRfq[0].status !== "Draft") {
        if (updatedRfq[0].rfq_option_request) {
          updatedRfq[0].color = true;
          updatedRfq[0].checked = false;
          dispatch(addNewRfqFromWebSocketToRfqArray(updatedRfq[0]));
          dispatch(controleNotificationAcount(updatedRfq[0]));

          setTimeout(() => {
            dispatch(idOfRfq(updatedRfq[0].rfq_id));
          }, 30000);
        }
      }
    }
  };
export const createRfqCtrlEnter = (rfqObject) => async (dispatch, getState) => {
  const statusCtrlEnter = getState().rfqSlice.statusCtrlEnter;
  const status = getState().rfqSlice.rfqButtonStatus;
  const rfqstatus = getState().rfqSlice.isRfqStatusUpdateLive;
  const rfq = getState().rfqSlice.rfq;
  const filteredRfq = rfq.filter(
    (r) => Number(r.rfq_id) === Number(rfqObject.rfq_id),
  );
  if (status === "copy") {
    return {
      ...rfqObject,
      status: statusCtrlEnter !== "" ? statusCtrlEnter : "Live",
      rfqStatus: rfqstatus,
    };
  } else if (rfqObject.rfq_id === null) {
    return {
      ...rfqObject,
      status: statusCtrlEnter !== "" ? statusCtrlEnter : "Live",
      rfqStatus: rfqstatus,
    };
  } else if (rfqObject.rfq_id !== null) {
    return {
      ...rfqObject,
      status: statusCtrlEnter !== "" ? statusCtrlEnter : filteredRfq[0].status,
      rfqStatus: rfqstatus,
    };
  }
};
export const getStatus = () => async (dispatch, getState) => {
  const status = getState().rfqSlice.rfqButtonStatus;
  return status;
};
export const rfqRecommendationHandler =
  (data) => async (dispatch, getState) => {
    const { recommendationDetails, rfqId } = data;
    const recommendationRfqsCopy = [...getState().rfqSlice.recommendationRfqs];
    const index = recommendationRfqsCopy.findIndex(
      (rfq) => Number(rfq.rfqId) === Number(rfqId),
    );

    if (index === -1) {
      recommendationRfqsCopy.push({ rfqId, recommendationDetails });
      dispatch(updateRecommendationRfqs(recommendationRfqsCopy));
    }
  };

export const handlePageRefresh = (id) => async (dispatch, getState) => {
  const rfqId = getState().rfqSlice.rfqId;

  const token = sessionStorage.getItem("token");
  if (rfqId.isLoading === true) {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.RFQ +
        `/rfq_details/${id}`,
      { headers: { Authorization: token } },
    );
  } else {
    console.log("we got the rfq", rfqId);
  }
};
export const selectNewRfqTab =
  (rfqDescription = "NEW RFQ") =>
  async (dispatch, getState) => {
    const rfqInstanceList = [...getState().rfqSlice.rfqInstanceList];
    const mappedRfqInstanceList = rfqInstanceList.map((ins) => {
      if (ins.rfq_description === rfqDescription) {
        return { ...ins, tabIsChosen: true };
      } else {
        return { ...ins, tabIsChosen: false };
      }
    });
    dispatch(updatRfqInstanceList(mappedRfqInstanceList));
  };
export const removeTabHandler =
  (id, e, navigateRfqs) => async (dispatch, getState) => {
    const rfqInstanceList = [...getState().rfqSlice.rfqInstanceList];
    const index = rfqInstanceList.findIndex(
      (obj) => Number(obj.id) === Number(id),
    );
    if (index !== -1) {
      if (rfqInstanceList[index].tabIsChosen === true) {
        if (index !== 0) {
          rfqInstanceList[index - 1] = {
            ...rfqInstanceList[index - 1],
            tabIsChosen: true,
          };
          navigateRfqs(e, rfqInstanceList[index - 1]);
        } else {
          rfqInstanceList[index + 1] = {
            ...rfqInstanceList[index + 1],
            tabIsChosen: true,
          };
          navigateRfqs(e, rfqInstanceList[index + 1]);
        }
      }
      rfqInstanceList.splice(index, 1);
    }
    dispatch(updatRfqInstanceList(rfqInstanceList));
    //         const updateUserSql = `UPDATE users_tabs SET tabs = ? WHERE user_id =?;`;
    //         const updateUserParams = [JSON.stringify(tabsList), userId];
    //         await db.executeWithParameters(updateUserSql, updateUserParams)
  };

export const handleCloseTabs =
  (key, navigateRfqs, e) => async (dispatch, getState) => {
    try {
      const token = sessionStorage.getItem("token");
      const rfqInstanceList = [...getState().rfqSlice.rfqInstanceList];
      let list;
      if (key === "close all") {
        list = rfqInstanceList.filter(
          (tab) => tab.rfq_description === "NEW RFQ",
        );
        list = [{ ...list[0], tabIsChosen: true }];
        dispatch(updatRfqInstanceList(list));
      } else if (key === "close other tabs") {
        list = rfqInstanceList.filter(
          (tab) =>
            tab.rfq_description === "NEW RFQ" || tab.tabIsChosen === true,
        );
        dispatch(updatRfqInstanceList(list));
      }
      const obj = {
        tabs: list,
        userId: sessionStorage.getItem("id"),
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.CLEAN_TABS,
        obj,
        { headers: { Authorization: token } },
      );
      navigateRfqs(e, list[0]);
    } catch (error) {}
  };

export const handleDeleteRfq = (token) => async (dispatch, getState) => {
  try {
    const rfqId = sessionStorage.getItem("rfqId");
    const statusValue = "Deleted";
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}` + `rfq/update_status`,
      { rfqId, statusValue },
      {
        headers: { Authorization: token },
      },
    );
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${END_POINT.DELETE_RFQ_FROM_TAB_LIST}`,
      { rfqId },
      {
        headers: { Authorization: token },
      },
    );
  } catch (error) {}
};
export const {
  getRfqSuccess,
  changeStatusChecked,
  addNewRfqFromWebSocketToRfqArray,
  idOfRfq,
  addHidenKey,
  controleNotificationAcount,
  removeWsObjectAcouunt,
  startCurrencyPairsLoading,
  getCurrencyPairsSuccess,
  startPbAccountLoading,
  getPbAccountSuccess,
  removeHidenKey,
  restartNotificationAcount,
  getDealTypesSuccess,
  updateRfqObject,
  updatTraderNote,
  getClientsSuccess,
  updatRfqButtonStatus,
  clearRfqButtonStatus,
  getRfqByIdSuccess,
  setRfqIsLoading,
  getRfqByIdFailed,
  updatRfqInstanceList,
  addRfqInstanceList,
  clearRfqId,
  getRfqCheckedSuccess,
  updateCreatedRfqDetails,
  clearrfqInstanceList,
  clearCreatedRfqDetails,
  clearrfqButtonStatus,
  clearRfq,
  updateTableData,
  clearDealStatsData,
  updateDealStatsData,
  clearTraderNote,
  swapTradeNote,
  updateRfqStr,
  updateOvmlTail,
  cleanRfqStr,
  cleanOvmlTail,
  updateRqObjectUpdate,
  setClientListSuccess,
  updateRfqSuccess,
  clearRfqState,
  updateHighlightedRfqTab,
  updateRfqBookingModeUpdatingRows,
  clearRqObjectUpdate,
  updateLpPerdiction,
  updateSocketStatus,
  setIsNavigateToRfqBlotter,
  updateRfqLocationMode,
  setStatusItem,
  setStatusCtrlEnter,
  updateRequestFor,
  updateIsRfqStatusUpdateLive,
  updateRecommendationRfqs,
  getSingleCurrenciesSuccess,
} = rfqSlice.actions;

export default rfqSlice.reducer;
