import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Dialog,
  DialogTitle,
  Button,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTextField,
  useStyles,
} from "./Clients.styles";
import { ReactComponent as Edit } from "../../../assets/edit.svg";
import { ReactComponent as Delete } from "../../../assets/delete.svg";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@mui/material";
import { useState } from "react";
function ClientTableRowView(props) {
  const classes = useStyles();
  const {
    isEdit,
    client,
    chosenPms,
    setChosenPms,
    setChosenAgreement,
    setClientDefaultEmail,
    setIsEdit,
    handleEdit,
    clientName,
    clientIndex,
    deleteClient,
    clientCompany,
    setClientName,
    handlePmChange,
    chosenAgreement,
    clientShortName,
    clientDefaultEmail,
    setClientShortName,
    handleAgreementChange,
    changeClientDefaultEmail,
    handeClientCompanyChange,
    routerCreteOrUpdateClient,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const deleteClientAsync = async (clientId, clientIndex) => {
    let res = await deleteClient(clientId, clientIndex);
    if (res === "deleted") {
      setDialogOpen(false);
    }
  };

  const resetStateWhenCancelEdit = () => {
    setChosenPms([]);
    setChosenAgreement("");
    setClientDefaultEmail("");
    setIsEdit(false);
  };

  return (
    <>
      <StyledTableRow style={{ height: "40px" }}>
        {Object.entries(client).map(([key, value], index) => {
          if (key === "client_name") {
            return (
              <StyledTableCell
                style={{ width: key === "client_name" ? "20px" : "auto" }}
              >
                {isEdit === false ? (
                  clientName !== "" ? (
                    clientName
                  ) : (
                    value
                  )
                ) : (
                  <StyledTextField
                    value={clientName !== "" ? clientName : value}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                )}
              </StyledTableCell>
            );
          } else if (key === "short_name") {
            return (
              <StyledTableCell
                style={{ width: key === "client_name" ? "20px" : "auto" }}
              >
                {isEdit === false ? (
                  clientShortName !== "" ? (
                    clientShortName
                  ) : (
                    value
                  )
                ) : (
                  <StyledTextField
                    value={clientShortName !== "" ? clientShortName : value}
                    onChange={(e) => setClientShortName(e.target.value)}
                  />
                )}
              </StyledTableCell>
            );
          } else if (key === "company") {
            return (
              <StyledTableCell>
                {isEdit ? (
                  <FormControl fullWidth>
                    <Select
                      className={classes.select}
                      value={clientCompany}
                      onChange={handeClientCompanyChange}
                    >
                      <MenuItem value={"oscar gruss"}>oscar gruss rbs</MenuItem>
                      <MenuItem value={"makor"}>makor rbs</MenuItem>
                      <MenuItem value={"makor hsbc"}>makor hsbc</MenuItem>
                      <MenuItem value={"makor scb"}>makor scb</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <Typography style={{ fontFamily: "'Mulish', sans-serif" }}>
                    {clientCompany !== ""
                      ? clientCompany
                      : value === "makor"
                      ? "makor rbs"
                      : value === "oscar gruss"
                      ? "oscar gruss rbs"
                      : "makor hsbc"}
                  </Typography>
                )}
              </StyledTableCell>
            );
          } else if (key === "agreement") {
            return (
              <StyledTableCell>
                {isEdit ? (
                  <FormControl fullWidth>
                    <Select
                      disabled={isEdit ? false : true}
                      className={classes.select}
                      value={chosenAgreement}
                      onChange={handleAgreementChange}
                    >
                      <MenuItem value={"4way"}>4way</MenuItem>
                      <MenuItem value={"Carveout"}>Carveout</MenuItem>
                    </Select>
                  </FormControl>
                ) : chosenAgreement !== "" ? (
                  chosenAgreement
                ) : (
                  value
                )}
              </StyledTableCell>
            );
          } else if (key === "default_email") {
            return (
              <StyledTableCell
                style={{ width: key === "client_name" ? "20px" : "auto" }}
              >
                {isEdit === false ? (
                  <Typography
                    style={{
                      color:
                        clientDefaultEmail !== "" &&
                        clientDefaultEmail !== null &&
                        value !== null
                          ? "#ffffff"
                          : "green",
                    }}
                  >
                    {clientDefaultEmail !== "" && clientDefaultEmail !== null
                      ? value !== null
                        ? value
                        : "No recipients"
                      : clientDefaultEmail}
                  </Typography>
                ) : (
                  <StyledTextField
                    value={
                      clientDefaultEmail !== "" && clientDefaultEmail === null
                        ? value
                        : clientDefaultEmail
                    }
                    onChange={(e) => changeClientDefaultEmail(e)}
                  />
                )}
              </StyledTableCell>
            );
          } else if (key === "pmsList") {
            return (
              <StyledTableCell
                style={{
                  maxWidth: "200px",
                }}
              >
                {isEdit ? (
                  <FormControl fullWidth>
                    <Select
                      multiple
                      className={classes.select}
                      value={chosenPms}
                      onChange={handlePmChange}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {client.pmsList.map((pm, index) => (
                        <MenuItem key={pm.pm} value={{ pm: pm.pm, id: pm.id }}>
                          <Checkbox checked={chosenPms.indexOf(pm.pm) > -1} />
                          <ListItemText primary={pm.pm} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontFamily: "'Mulish', sans-serif",
                    }}
                  >
                    {chosenPms.length > 0
                      ? chosenPms.join(", ")
                      : client.pm_names !== null
                      ? client.pm_names
                      : "No Pms attached"}
                  </Typography>
                )}
              </StyledTableCell>
            );
          }
        })}
        <StyledTableCell style={{ borderLeft: "1px solid #b3b3b3" }}>
          {isEdit === false ? (
            <Grid item container justifyContent="space-evenly">
              <Grid item>
                <IconButton size="small" onClick={handleEdit}>
                  <Edit />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={() => setDialogOpen(true)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <Grid item container justifyContent="space-evenly">
              <Grid item>
                <IconButton size="small" onClick={routerCreteOrUpdateClient}>
                  <DoneIcon style={{ color: "#15ba77" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => resetStateWhenCancelEdit()}
                >
                  <CloseIcon style={{ color: "#e30606" }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <Dialog open={dialogOpen} classes={{ paper: classes.deleteDialog }}>
        <DialogTitle
          style={{ textAlign: "center", fontFamily: "'Mulish', sans-serif" }}
        >
          Confirm Delete
        </DialogTitle>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={11} style={{ textAlign: "center", paddingBottom: 20 }}>
            <Typography
              style={{
                fontSize: "1.125rem",
                fontFamily: "'Mulish', sans-serif",
              }}
            >
              {`Are you sure you want to delete ${client.client_name} ?`}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-evenly">
            <Grid item>
              <Button
                style={{ backgroundColor: "#56df95" }}
                onClick={() => deleteClientAsync(client.client_id, clientIndex)}
              >
                Yes
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ backgroundColor: "#ff6464" }}
                onClick={() => setDialogOpen(false)}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default ClientTableRowView;
