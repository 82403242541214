import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { useStyles } from "../style/style";
import { tabsList } from "../utils/tabsList";

export const TabManager = ({ selectedTab, setSelectedTab }) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      classes={{ indicator: classes.indicator }}
      className={classes.tabs}
    >
      {tabsList.map((t) => {
        return (
          <Tab
            key={t.id}
            value={t.value}
            label={t.label}
            className={classes.tab}
          />
        );
      })}
    </Tabs>
  );
};
