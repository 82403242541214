export function processStringToArray(data) {
  // Validate input data and ensure it's a string
  if (typeof data !== "string" || !data.trim()) {
    return []; // Return an empty array for invalid input
  }

  // Split the string into lines
  const lines = data.trim().split("\n");

  // Ensure there are at least two lines (headers + one row)
  if (lines.length < 2) {
    return []; // Return an empty array if no rows exist
  }

  // Map for header renaming
  const headerMapping = {
    Buyer: "buyer",
    "Buyer acc": "buyerAccount",
    Seller: "seller",
    "Seller Acc": "sellerAccount",
    "BBG Ticker": "bbgCode",
    Quantity: "quantity",
    Price: "price",
  };

  // Extract and map headers to new names
  const rawHeaders = lines[0].split("\t");
  const headers = rawHeaders.map((header) => headerMapping[header] || header);

  // Process the data rows
  const result = lines.slice(1).map((line) => {
    const values = line.split("\t");
    // Map new headers to values
    return headers.reduce((obj, header, index) => {
      obj[header] = values[index] || null; // Handle missing values
      return obj;
    }, {});
  });

  // Return the result as an array
  return Array.isArray(result) ? result : [];
}
