import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import { textAlign } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import * as clientActions from "../../../store/fi/fiClientsSlice";
import { convertDate } from "../datePicker/convertDate";

export default function FreeTextInput(values) {
  const dispatch = useDispatch();
  const [color, setColor] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [key, setKey] = useState("");
  const formData = useSelector((state) => state.fiSlice?.newTradeStats);
  const tradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit,
  );
  const { selectedClient, newClientValues } = useSelector(
    (state) => state.fiClientsSlice,
  );
  const selectedClientKeys = Object.keys(selectedClient);
  useEffect(() => {
    if (values.values.type !== "new") {
      if (
        tradeToEdit !== undefined &&
        values.values.key !== "securityName" &&
        values.values.key !== "bbgMakorCode" &&
        // values.values.key !== "brokerRef" &&
        values.values.key !== "firmName" &&
        values.values.key !== "subAccountsCode" &&
        values.values.key !== "agentName" &&
        values.values.key !== "agentBic" &&
        values.values.key !== "localCode" &&
        values.values.key !== "clearanceAc" &&
        values.values.key !== "lei" &&
        values.values.key !== "account" &&
        values.values.key !== "confirmationEmail1" &&
        values.values.key !== "confirmationEmail2" &&
        values.values.key !== "confirmationEmail3" &&
        values.values.key !== "confirmationEmail4" &&
        values.values.key !== "merrillAcc"
      ) {
        setSelectedValue(tradeToEdit[`${values.values.key}`]);
        const obj = {
          value: tradeToEdit[`${values.values.key}`],
          key: values.values.key,
        };
        dispatch(fiActions.editNewTradeForm(obj));
      } else if (
        tradeToEdit !== undefined &&
        values.values.key === "securityName" &&
        values.values.key !== "bbgMakorCode" &&
        // values.values.key !== "brokerRef" &&
        values.values.key !== "firmName" &&
        values.values.key !== "subAccountsCode" &&
        values.values.key !== "agentName" &&
        values.values.key !== "agentBic" &&
        values.values.key !== "localCode" &&
        values.values.key !== "clearanceAc" &&
        values.values.key !== "lei" &&
        values.values.key !== "account" &&
        values.values.key !== "confirmationEmail1" &&
        values.values.key !== "confirmationEmail2" &&
        values.values.key !== "confirmationEmail3" &&
        values.values.key !== "confirmationEmail4" &&
        values.values.key !== "merrillAcc"
      ) {
        setSelectedValue(tradeToEdit[`security_name`]);
        const obj = {
          value: tradeToEdit[`security_name`],
          key: values.values.key,
        };
        dispatch(fiActions.editNewTradeForm(obj));
      } else if (
        values.values.key === "bbgMakorCode" ||
        // values.values.key === "brokerRef" ||
        values.values.key === "firmName" ||
        values.values.key === "subAccountsCode" ||
        values.values.key === "agentName" ||
        values.values.key === "agentBic" ||
        values.values.key === "localCode" ||
        values.values.key === "clearanceAc" ||
        values.values.key === "lei" ||
        values.values.key === "account" ||
        values.values.key === "confirmationEmail1" ||
        values.values.key === "confirmationEmail2" ||
        values.values.key === "confirmationEmail3" ||
        values.values.key === "confirmationEmail4" ||
        values.values.key === "merrillAcc"
      ) {
        if (
          newClientValues.ssi !== selectedClient.ssi &&
          selectedClientKeys.length > 0
        ) {
          if (
            newClientValues.ssi !== "FDNY" &&
            (values.values.key === "agentName" ||
              values.values.key === "agentBic" ||
              values.values.key === "localCode" ||
              values.values.key === "clearanceAc")
          ) {
            const obj = {
              value: "",
              key: values.values.key,
            };
            dispatch(clientActions.editNewClientValues(obj));
            setSelectedValue("");
          } else if (
            newClientValues.ssi === "FDNY" &&
            values.values.key === "account"
          ) {
            const obj = {
              value: "",
              key: values.values.key,
            };
            dispatch(clientActions.editNewClientValues(obj));
            setSelectedValue("");
          } else {
            setSelectedValue(selectedClient[`${values.values.key}`]);
            const obj = {
              value: selectedClient[`${values.values.key}`],
              key: values.values.key,
            };
            dispatch(clientActions.editNewClientValues(obj));
          }
        } else if (selectedClientKeys.length > 0) {
          setSelectedValue(selectedClient[`${values.values.key}`]);
          const obj = {
            value: selectedClient[`${values.values.key}`],
            key: values.values.key,
          };
          dispatch(clientActions.editNewClientValues(obj));
        }
      }
    }
  }, []);

  useEffect(() => {
    setKey(values.values.key);
  }, [values.values.key]);
  // useEffect(() => {

  // }, [newClientValues]);

  const useStyles = makeStyles((theme) => ({
    freeTextPicker: {
      marginTop: "25px",
      "& .MuiSelect-select:not([multiple]) option": {
        backgroundColor: "#2D2D2D",
      },
      "& .MuiOutlinedInput-input": {
        color: "#FFFFFF",
        borderRadius: "4px",
        height: "32px",
        width: "210px",
        padding: 0,
        textAlign: "left",
        paddingLeft: "10px",
      },
      "& .MuiInputLabel-root": {
        color: "#868DA2",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#686B76",
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "&:hover .MuiInputLabel-root": {
        color: "#2692FF",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2692FF",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#2692FF",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2692FF",
      },
    },
  }));

  const classes = useStyles();

  const handleChange = (event) => {
    setColor(!color);
    setSelectedValue(event.target.value);
    const obj = {
      value: event.target.value,
      key: values.values.key,
    };
    if (
      values.values.key !== "bbgMakorCode" &&
      // values.values.key !== "brokerRef" &&
      values.values.key !== "firmName" &&
      values.values.key !== "subAccountsCode" &&
      values.values.key !== "agentName" &&
      values.values.key !== "agentBic" &&
      values.values.key !== "localCode" &&
      values.values.key !== "clearanceAc" &&
      values.values.key !== "lei" &&
      values.values.key !== "account" &&
      values.values.key !== "confirmationEmail1" &&
      values.values.key !== "confirmationEmail2" &&
      values.values.key !== "confirmationEmail3" &&
      values.values.key !== "confirmationEmail4" &&
      values.values.key !== "merrillAcc"
    ) {
      if (values.values.key === "isin") {
        const newIsin = event.target.value;
        let settlementDate = new Date();

        if (newIsin.startsWith("US")) {
          let tradeDate = new Date(formData.tradeDate);
          settlementDate = addOneWeekday(tradeDate);
        } else {
          let daysToAdd = 0;
          const dayNum = settlementDate.getDay();

          switch (dayNum) {
            case 0: // Sunday
              daysToAdd = 2;
              break;
            case 1: // Monday
              daysToAdd = 2;
              break;
            case 2: // Tuesday
              daysToAdd = 2;
              break;
            case 3: // Wednesday
              daysToAdd = 2;
              break;
            case 4: // Thursday
              daysToAdd = 4;
              break;
            case 5: // Friday
              daysToAdd = 4;
              break;
            case 6: // Saturday
              daysToAdd = 3;
              break;
            default:
              break;
          }
          settlementDate.setDate(settlementDate.getDate() + daysToAdd);
        }
        // const settlementDateObj = { key: "settlementDate", value: settlementDate };
        const obj = {
          value: event.target.value,
          key: values.values.key,
        };
        const settlementDateObj = convertDate(settlementDate, "settlementDate");
        dispatch(fiActions.editNewTradeForm(settlementDateObj));
        dispatch(fiActions.editNewTradeForm(obj));
      }
      dispatch(fiActions.editNewTradeForm(obj));
    } else if (
      values.values.key === "bbgMakorCode" ||
      // values.values.key === "brokerRef" ||
      values.values.key === "firmName" ||
      values.values.key === "subAccountsCode" ||
      values.values.key === "agentName" ||
      values.values.key === "agentBic" ||
      values.values.key === "localCode" ||
      values.values.key === "clearanceAc" ||
      values.values.key === "lei" ||
      values.values.key === "account" ||
      values.values.key === "confirmationEmail1" ||
      values.values.key === "confirmationEmail2" ||
      values.values.key === "confirmationEmail3" ||
      values.values.key === "confirmationEmail4" ||
      values.values.key === "merrillAcc"
    ) {
      dispatch(clientActions.editNewClientValues(obj));
    }

    // }
  };

  const addOneWeekday = (date) => {
    let newDate = new Date(date);
    let dayNum = newDate.getDay();
    if (dayNum === 6) {
      newDate.setDate(newDate.getDate() + 2);
    }
    else if (dayNum === 0) {
      newDate.setDate(newDate.getDate() + 1);
    }
    else {
      newDate.setDate(newDate.getDate() + 1);
    }
    return newDate;
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          className={classes.freeTextPicker}
          style={{
            width:
              key === "merrillAcc"
                ? "90px"
                : key === "isin"
                  ? "140px"
                  : key === "securityName"
                    ? "220px"
                    : "210px",
            marginTop: key === "merrillAcc" ? "0" : "25px",
            marginLeft: key === "securityName" ? "22px" : "0", //added because of isStructured checkbox flag
          }}
          id="outlined-select-currency-native"
          type="text"
          label={values.values.label}
          value={selectedValue}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          inputProps={{
            maxLength: key === "merrillAcc" ? 8 : undefined,
            pattern: key === "merrillAcc" ? "^[a-zA-Z0-9]*$" : undefined,
          }}
        ></TextField>
      </div>
    </form>
  );
}
