// export const generateRandomId = (length = 5) => {
//   let result = '';
//   for (let i = 0; i < length; i++) {
//     result += Math.floor(Math.random() * 10);
//   }
//   return result;
// }

const generatedIds = new Set();

export const generateRandomId = (length = 10) => {
  let result;
  do {
    result = '';
    for (let i = 0; i < length; i++) {
      result += Math.floor(Math.random() * 10);
    }
  } while (generatedIds.has(result));

  generatedIds.add(result);
  return result;
};
