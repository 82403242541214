import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { useEffect, useState } from "react";
import * as rfqPostTradeSlice from "../../../../store/rfq/rfqPostTradeSlice";
import * as emailActions from "../../../../store/email_confo/emailConfoSlice";
import * as actionSnackBar from "../../../../store/snackbar/action";
import * as rfqActions from "../../../../store/rfq/rfqSlice";
import FxEmailContentView from "./FxEmailContent.view";
function FxEmailContent(props) {
  const { clientIndex, tradeData } = props;
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const [toEmailAddresses, setToEmailAddresses] = useState(
    useSelector((state) => state.rfqPostTradeSlice?.toEmailAddresses),
  );
  const [ccEmailAddresses, setCCEmailAddresses] = useState(
    useSelector((state) => state.rfqPostTradeSlice?.ccEmailAddresses),
  );
  const rfqEmail = useSelector((state) => state.emailSlice?.rfqEmail);
  const [subject, setSubject] = useState();
  const singleConfo = useSelector(
    (state) => state.rfqPostTradeSlice?.singleConfo,
  );
  const thereIsConfo = useSelector(
    (state) => state.rfqPostTradeSlice?.thereIsConfo,
  );
  const currentClient = useSelector(
    (state) => state.rfqPostTradeSlice?.currentClient,
  );
  const allocationsArray = useSelector(
    (state) => state.rfqPostTradeSlice?.postTradeSplitArrValues,
  );

  let rfqBookingModeUpdatingRows = useSelector(
    (state) => state.rfqSlice.rfqBookingModeUpdatingRows,
  );
  let rfq = useSelector((state) => state.rfqSlice.rfq);
  let savedFxEmailData = useSelector(
    (state) => state.emailSlice.fxEmailsSavedData,
  );

  let {
    currentMarketMaker,
    makorClients,
    makorMarketMakers,
    allMakorHSBCClientAndMarketMakers,
    makorHSBCClient,
    makorHSBCMarketMakers,
    oscarGrussClients,
    oscarGrussMarketMakers,
    postTradeSplitArrValues,
    sd,
  } = useSelector((state) => state.rfqPostTradeSlice);

  const emailSent = useSelector((state) => state.emailSlice?.emailSent);
  const nodeJsEmailBodyClientSelection = useSelector(
    (state) => state.emailSlice.nodeJSEmailBodyClientSelection,
  );
  const rfq_obj = useSelector((state) => state.rfqPostTradeSlice?.rfq_object);
  const [sendStatus, setSendStatus] = useState(emailSent ? "success" : "");
  const [startSendEmail, setStartSendEmail] = useState(emailSent);
  const [tooltip, setTooltip] = useState({
    open: false,
    title: "To edit the email you must edit the RFQ",
  });

  const [traderComment, setTraderComment] = useState("");
  const [bottomComment, setBottomComment] = useState("");

  const sendEmail = async () => {
    let sender = "MAKOR";
    let currentClientShortName;
    let allocation_list = postTradeSplitArrValues.map((key) => {
      return {
        client_selection: key.postTradeClientSplitId,
        pm_selection: key.postTradePmSplitId,
        percentage_allocation: +key.postTradeSplitOption / 100,
        share_percentage: key.postTradeSplitOption,
      };
    });
    if (currentClient[clientIndex].includes("(MM)") === false) {
      if (allocation_list.length === 0) {
        if (currentMarketMaker === "MAKOR-RBS") {
          if (sd === true) {
            currentClientShortName = oscarGrussClients.find(
              (client) => client?.name === currentClient[clientIndex],
            );
            currentClientShortName = currentClientShortName.shortname;
            sender = "OG";
          } else {
            currentClientShortName = makorClients.find(
              (client) => client?.name === currentClient[clientIndex],
            );
            currentClientShortName = currentClientShortName.shortname;
          }
        } else if (currentMarketMaker === "MAKOR-HSBC") {
          currentClientShortName = allMakorHSBCClientAndMarketMakers.find(
            (client) => client?.name === currentClient[clientIndex],
          );
          currentClientShortName = currentClientShortName.shortname;
        } else if (currentMarketMaker === "OG-RBS") {
          currentClientShortName = oscarGrussClients.find(
            (client) => client?.name === currentClient[clientIndex],
          );
          currentClientShortName = currentClientShortName.shortname;
          sender = "OG";
        }
      } else {
        if (currentMarketMaker === "MAKOR-RBS") {
          if (sd === true) {
            currentClientShortName = oscarGrussClients.find(
              (client) =>
                client?.name ===
                allocation_list[clientIndex].client_selection,
            );
            currentClientShortName = currentClientShortName.shortname;
            sender = "OG";
          } else {
            currentClientShortName = makorClients.find(
              (client) =>
                client?.name ===
                allocation_list[clientIndex].client_selection,
            );
            currentClientShortName = currentClientShortName.shortname;
          }
        } else if (currentMarketMaker === "MAKOR-HSBC") {
          currentClientShortName = allMakorHSBCClientAndMarketMakers.find(
            (client) =>
              client?.name === allocation_list[clientIndex].client_selection,
          );
          currentClientShortName = currentClientShortName.shortname;
        } else if (currentMarketMaker === "OG-RBS") {
          currentClientShortName = oscarGrussClients.find(
            (client) =>
              client?.name === allocation_list[clientIndex].client_selection,
          );
          currentClientShortName = currentClientShortName.shortname;
          sender = "OG";
        }
      }
    } else {
      if (currentMarketMaker === "MAKOR-RBS") {
        currentClientShortName = makorMarketMakers.find(
          (client) =>
            client?.name === currentClient[clientIndex].replace(" (MM)", ""),
        );
        if (currentClientShortName !== undefined) {
          currentClientShortName = currentClientShortName.shortname;
        } else {
          currentClientShortName = oscarGrussClients.find(
            (client) =>
              client?.name ===
              currentClient[clientIndex].replace(" (MM)", ""),
          );
          currentClientShortName = currentClientShortName.shortname;
          sender = "OG";
        }
      } else if (currentMarketMaker === "MAKOR-HSBC") {
        currentClientShortName = allMakorHSBCClientAndMarketMakers.find(
          (client) =>
            client?.name === currentClient[clientIndex].replace(" (MM)", ""),
        );
        currentClientShortName = currentClientShortName.shortname;
      } else if (currentMarketMaker === "OG-RBS") {
        currentClientShortName = oscarGrussMarketMakers.find(
          (client) =>
            client?.name === currentClient[clientIndex].replace(" (MM)", ""),
        );
        currentClientShortName = currentClientShortName.shortname;
        sender = "OG";
      }
    }

    if (nodeJsEmailBodyClientSelection === currentClientShortName) {
      setStartSendEmail(true);
      dispatch(rfqPostTradeSlice.setStartSendEmailStatus(true));
      setSendStatus("start");
      try {
        let emailBody =
          document.getElementsByClassName("emailToSend")[0].innerHTML;
        let res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}` + "email/send_fx_email",
          {
            from: currentMarketMaker,
            to: toEmailAddresses,
            cc: ccEmailAddresses,
            subject: subject === undefined ? rfqEmail.subject : subject,
            html: emailBody,
            sd: sd,
            mmConfo: currentClient[clientIndex].includes("(MM)") ? true : false,
            sender,
          },
          {
            headers: { Authorization: token },
          },
        );
        try {
          setSendStatus("success");
          dispatch(rfqPostTradeSlice.setStartSendEmailStatus(false));
          let rfqEmailObj = rfqEmail;
          if (subject !== "" && subject !== undefined) {
            rfqEmailObj = { ...rfqEmailObj, subject: subject };
          }
          dispatch(
            rfqPostTradeSlice.saveConfoOnDataBase(
              clientIndex,
              rfqEmailObj,
              toEmailAddresses,
              ccEmailAddresses,
              traderComment,
              bottomComment,
            ),
          );
          tradeData[1].rfqBookingModeUpdatingRows = rfqBookingModeUpdatingRows;

          let saveTrades = await axios.post(
            `${process.env.REACT_APP_BASE_URL}` + `trades`,
            { rfq_object: tradeData[1] },
            { headers: { Authorization: token } },
          );
          dispatch(rfqPostTradeSlice.removeRfqFromPreValues(rfq_obj?.rfq_id));
          dispatch(rfqActions.updateLocalRfq(saveTrades.data));
        } catch (error) {
          throw error;
        }
      } catch (error) {
        setSendStatus("fail");
        // console.log("error",error.response)
        dispatch(
          actionSnackBar.setSnackBar("error", error.response.data, 8000),
        );
        setTimeout(() => {
          setStartSendEmail(false);
          dispatch(rfqPostTradeSlice.setStartSendEmailStatus(false));
        }, 3000);
      }
    } else {
      setStartSendEmail(false);
      dispatch(rfqPostTradeSlice.setStartSendEmailStatus(false));
      setSendStatus("fail");
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "Differences between current RFQ to Email Body",
          8000,
        ),
      );
    }
  };

  const handleEmailAddressAdd = (type, event) => {
    if (event.target.value.startsWith(";")) {
      event.target.value = event.target.value.replace(";", "");
    }
    if (type === "to") {
      let newArr = event.target.value.split(/[\;\,\:]/);
      newArr = newArr.map((address) => {
        return address.replaceAll(" ", "");
      });
      setToEmailAddresses(newArr);
      dispatch(
        emailActions.setEmailSavedData("add", "toEmails", clientIndex, newArr),
      );
    }
    if (type === "cc") {
      let newArr = event.target.value.split(/[\;\,\:]/);
      newArr = newArr.map((address) => {
        return address.replaceAll(" ", "");
      });
      setCCEmailAddresses(newArr);
      dispatch(
        emailActions.setEmailSavedData("add", "ccEmails", clientIndex, newArr),
      );
    }
  };

  const editSubject = (event) => {
    setSubject(event.target.value);
    dispatch(
      emailActions.setEmailSavedData(
        "add",
        "subjects",
        clientIndex,
        event.target.value,
      ),
    );
  };

  const generateComment = (event, type) => {
    if (type === "bottom") {
      if (event.key === "Enter") {
        setBottomComment(bottomComment + "\n");
      } else {
        setBottomComment(event.target.value);
      }
      dispatch(
        emailActions.setEmailSavedData(
          "add",
          "bottomComments",
          clientIndex,
          event.target.value,
        ),
      );
    } else {
      if (event.key === "Enter") {
        setTraderComment(traderComment + "\n");
      } else {
        setTraderComment(event.target.value);
      }
      dispatch(
        emailActions.setEmailSavedData(
          "add",
          "traderComments",
          clientIndex,
          event.target.value,
        ),
      );
    }
  };

  useEffect(() => {
    if (savedFxEmailData?.toEmails?.length > 0) {
      setToEmailAddresses(savedFxEmailData?.toEmails[clientIndex]);
    }
    if (savedFxEmailData?.ccEmails?.length > 0) {
      setCCEmailAddresses(savedFxEmailData?.ccEmails[clientIndex]);
    }
    if (
      savedFxEmailData?.subjects?.length > 0 &&
      savedFxEmailData?.subjects[clientIndex] !== ""
    ) {
      setSubject(savedFxEmailData?.subjects[clientIndex]);
    }
    if (
      savedFxEmailData?.traderComments?.length > 0 &&
      savedFxEmailData?.traderComments[clientIndex] !== ""
    ) {
      setTraderComment(savedFxEmailData?.traderComments[clientIndex]);
    }
    if (
      savedFxEmailData?.bottomComments?.length > 0 &&
      savedFxEmailData?.bottomComments[clientIndex] !== ""
    ) {
      setBottomComment(savedFxEmailData?.bottomComments[clientIndex]);
    }
    return () => {
      setToEmailAddresses();
      setCCEmailAddresses();
      setSubject("");
      setTraderComment("");
      setBottomComment("");
    };
  }, []);

  return (
    <FxEmailContentView
      subject={subject}
      rfqEmail={rfqEmail}
      tooltip={tooltip}
      sendEmail={sendEmail}
      setTooltip={setTooltip}
      sendStatus={sendStatus}
      clientIndex={clientIndex}
      singleConfo={singleConfo}
      editSubject={editSubject}
      thereIsConfo={thereIsConfo}
      currentClient={currentClient}
      traderComment={traderComment}
      bottomComment={bottomComment}
      startSendEmail={startSendEmail}
      generateComment={generateComment}
      toEmailAddresses={toEmailAddresses}
      ccEmailAddresses={ccEmailAddresses}
      allocationsArray={allocationsArray}
      handleEmailAddressAdd={handleEmailAddressAdd}
    />
  );
}

export default FxEmailContent;
