import {
  jtable_keys,
  booking_mode_swp_hidden_rows,
  rfq_view_swp_hidden_rows,
} from "../../components/fx/rfq/rfqWorkspace/helpers/staticDataTable";
export const handleRfqWorkSpaceTableData = async (
  rfq,
  add_new_column,
  set_cell_value,
  removeHidenKey,
  addHidenKey,
  dispatch,
  jRef,
  assign_jexcel_data,
  rfqMode,
  rfqLength,
  get_ccypair_group_rfq_checked,
  addReadeadOnlyRowsInBookingModeBookedAndConfirmedRfq,
  deal_types,
  rfqBookingModeUpdatingRows,
  format_table,
) => {
  if (rfq[0].option_type_text !== "SWAP") {
    dispatch(addHidenKey("OPTION_TYPE"));
    if (rfqMode === "rfq booking view") {
      dispatch(removeHidenKey("HEDGE_TYPE"));
      dispatch(removeHidenKey("HEDGE_VAL"));
      dispatch(removeHidenKey("HEDGE_RATE"));
      dispatch(removeHidenKey("HEDGE_DELTA"));
      dispatch(removeHidenKey("PREM_CCY_TYPE"));
    }
    if (rfqMode === "rfq view" || rfqMode === "") {
      if (
        (rfq[0].option_type_text !== undefined
          ? rfq[0].option_type_text
          : rfq[0].option_type,
        "EXOTIC")
      ) {
        dispatch(addHidenKey("HEDGE_DELTA"));
      }
      if (rfq[0].option_type_text === "VANILLA") {
        dispatch(removeHidenKey("HEDGE_DELTA"));
      }
      if (rfq[0].including_ref === "YES") {
        dispatch(removeHidenKey("FIXING_REF"));
      } else if (rfq[0].including_ref === "NO") {
        dispatch(addHidenKey("FIXING_REF"));
      }
    }

    // for (let index = 0; index < Number(rfqLength) - 1; index++) {
    //   add_new_column();
    // }
    if (rfqLength === 1) {
      dispatch(addHidenKey("NET_MARKET_PRICE"));
      dispatch(addHidenKey("NET_CLIENT_PRICE"));
    } else if (rfqLength > 1) {
      if (rfqMode === "rfq booking view") {
        dispatch(removeHidenKey("NET_MARKET_PRICE"));
        dispatch(removeHidenKey("NET_CLIENT_PRICE"));
      } else {
        dispatch(addHidenKey("NET_CLIENT_PRICE"));
        dispatch(removeHidenKey("NET_MARKET_PRICE"));
      }
    }
    for (
      let index = 0;
      index < jRef.current.jspreadsheet.headers.length - 1;
      index++
    ) {
      let ccypair_group = get_ccypair_group_rfq_checked(rfq[index].ccy_pair);
      set_cell_value(rfq[index].ccy_pair, "CCY_PAIR", index + 1);
      set_cell_value(
        rfq[index].deal_type_text !== undefined
          ? rfq[index].deal_type_text
          : rfq[index].leg_type,
        "LEG_TYPE",
        index + 1,
      );
      set_cell_value(
        rfq[index].leg_type_text !== undefined
          ? rfq[index].leg_type_text
          : rfq[index].deal_type,
        "DEAL_TYPE",
        index + 1,
      );

      set_cell_value(
        rfq[index].option_type_text,

        "OPTION_TYPE",
        index + 1,
      );

      set_cell_value(rfq[index].expiry_text, "EXPIRY_TEXT", index + 1);
      set_cell_value(rfq[index].client_id, "REQUEST_FOR", index + 1);
      set_cell_value(rfq[index].ccypair_group, "CCYPAIR_GROUP", index + 1);
      if (
        rfq[index].deal_type_text !== "DNT" ||
        rfq[index].deal_type_text !== "OT" ||
        rfq[index].deal_type_text !== "OT_INSTANT" ||
        rfq[index].deal_type_text !== "NT" ||
        rfq[index].deal_type !== "DNT" ||
        rfq[index].deal_type !== "OT" ||
        rfq[index].deal_type !== "OT_INSTANT" ||
        rfq[index].deal_type !== "NT"
      ) {
        if (rfq[index].strike === "") {
          // dispatch(addHidenKey("STRIKE"));
        } else {
          dispatch(removeHidenKey("STRIKE"));
          set_cell_value(rfq[index].strike, "STRIKE", index + 1);
        }
      } else if (
        rfq[index].deal_type_text === "DNT" ||
        rfq[index].deal_type_text === "OT" ||
        rfq[index].deal_type_text === "OT_INSTANT" ||
        rfq[index].deal_type_text === "NT" ||
        rfq[index].deal_type === "DNT" ||
        rfq[index].deal_type === "OT" ||
        rfq[index].deal_type === "OT_INSTANT" ||
        rfq[index].deal_type === "NT"
      ) {
        dispatch(addHidenKey("STRIKE"));
      }
      let numberOfBearier0 = [
        "CALL_FLY",
        "CALL_LADDER",
        "CALL_SEAGULL",
        "CALL_SPREAD",
        "CALL_SPREAD_ROLL",
        "DIGITAL_CALL_SPREAD",
        "DIGITAL_PUT_SPREAD",
        "DIGITAL_VS_VANILLA",
        "PUT_FLY",
        "PUT_LADDER",
        "PUT_SEAGULL",
        "PUT_SPREAD",
        "PUT_SPREAD_ROLL",
        "RR",
        "SPREAD",
        "STRADDLE",
        "STRANGLE",
        "DIGITAL",
        "VANILLA",
      ];
      let numberOfBearier1 = [
        "DIGITAL_KO",
        "DIGITAL_KI",
        "DIGITAL_ERKO",
        "KO",
        "KI",
        "RKO",
        "DIGITAL_RKI",
        "ERKI",
        "ERKO",
        "NT",
        "OT",
        "OT_INSTANT",
        "RKI",
        "DIGITAL_RKO",
        "WKI",
        "WKO",
        "DIGITAL_WKI",
        "DIGITAL_WKO",
      ];
      let windowOption = [
        "WKI",
        "WKO",
        "WDKO",
        "WDKI",
        "DIGITAL_WKI",
        "DIGITAL_WKO",
        "DIGITAL_WDKO",
        "DIGITAL_WDKI",
      ];

      let numberOfBearier2 = [
        "DIGITAL_DKO",
        "DKI",
        "DKO",
        "DNT",
        "WDKI",
        "WDKO",
        "DIGITAL_WDKO",
        "DIGITAL_WDKI",
      ];
      if (
        numberOfBearier0.includes(rfq[index].deal_type_text) ||
        numberOfBearier0.includes(rfq[index].deal_type)
      ) {
        dispatch(addHidenKey("BARRIER"));
      }
      if (
        numberOfBearier1.includes(rfq[index].deal_type_text) ||
        numberOfBearier1.includes(rfq[index].deal_type)
      ) {
        dispatch(removeHidenKey("BARRIER"));
        dispatch(addHidenKey("BARRIER2"));
        set_cell_value(rfq[index].barrier_1, "BARRIER", index + 1);
      }
      if (
        numberOfBearier2.includes(rfq[index].deal_type_text) ||
        numberOfBearier2.includes(rfq[index].deal_type)
      ) {
        dispatch(removeHidenKey("BARRIER"));
        dispatch(removeHidenKey("BARRIER2"));
        set_cell_value(
          rfq[index].barrier_1 !== undefined
            ? rfq[index].barrier_1
            : rfq[index].barrier,
          "BARRIER",
          index + 1,
        );
        set_cell_value(
          rfq[index].barrier_2 !== undefined
            ? rfq[index].barrier_2
            : rfq[index].barrier2,
          "BARRIER2",
          index + 1,
        );
      }
      if (windowOption.includes(rfq[index].deal_type_text)) {
        dispatch(removeHidenKey("BARRIER_START_DATE"));
        dispatch(removeHidenKey("BARRIER_END_DATE"));
        set_cell_value(
          rfq[index].barrier_start_date,
          "BARRIER_START_DATE",
          index + 1,
        );
        set_cell_value(
          rfq[index].barrier_end_date,
          "BARRIER_END_DATE",
          index + 1,
        );
      } else {
        dispatch(addHidenKey("BARRIER_START_DATE"));
        dispatch(addHidenKey("BARRIER_END_DATE"));
        set_cell_value("", "BARRIER_START_DATE", index + 1);
        set_cell_value("", "BARRIER_END_DATE", index + 1);
      }

      if (
        rfq[index].deal_type_text === "DNT" ||
        rfq[index].deal_type_text === "OT" ||
        rfq[index].deal_type_text === "OT_INSTANT" ||
        rfq[index].deal_type_text === "NT" ||
        rfq[index].deal_type === "DNT" ||
        rfq[index].deal_type === "OT" ||
        rfq[index].deal_type === "OT_INSTANT" ||
        rfq[index].deal_type === "NT"
      ) {
        dispatch(addHidenKey("CALL_PUT"));
        set_cell_value(rfq[index].call_put, "CALL_PUT", index + 1);
      } else if (
        rfq[index].deal_type_text !== "DNT" ||
        rfq[index].deal_type_text !== "OT" ||
        rfq[index].deal_type_text !== "OT_INSTANT" ||
        rfq[index].deal_type_text !== "NT" ||
        rfq[index].deal_type !== "DNT" ||
        rfq[index].deal_type !== "OT" ||
        rfq[index].deal_type !== "OT_INSTANT" ||
        rfq[index].deal_type !== "NT"
      ) {
        dispatch(removeHidenKey("CALL_PUT"));
        set_cell_value(rfq[index].call_put, "CALL_PUT", index + 1);
      }

      set_cell_value(rfq[index].notional, "NOTIONAL", index + 1);
      set_cell_value(rfq[index].buy_sell, "BUY_SELL", index + 1);
      set_cell_value(rfq[index].pb_account, "PB_ACCOUNT", index + 1);
      set_cell_value(rfq[index].rfqFormat, "RFQ_FORMAT", index + 1);
      set_cell_value(rfq[index].refType, "REF_TYPE", index + 1);
      set_cell_value(rfq[index].include_ref, "INCLUDE_REF", index + 1);
      set_cell_value(rfq[index].cut, "CUT", index + 1);
      set_cell_value(rfq[index].fixing_ref, "FIXING_REF", index + 1);
      set_cell_value(rfq[index].notional_ccy, "NOTIONAL_CCY", index + 1);
      set_cell_value(rfq[index].spot_ref, "SPOT_REF", index + 1);
      set_cell_value(
        rfq[index].forward_ref === undefined
          ? rfq[index].fwd_ref
          : rfq[index].forward_ref,
        "FWD_REF",
        index + 1,
      );

      set_cell_value(rfq[index].bbg_mid_vol, "BBG_MID_VOL", index + 1);
      set_cell_value(
        rfq[index].expiry_date !== undefined
          ? rfq[index].expiry_date
          : rfq[index].expiry,
        "EXPIRY",
        index + 1,
      );
      set_cell_value(
        rfq[index].delivery_date !== undefined
          ? rfq[index].delivery_date
          : rfq[index].delivery,
        "DELIVERY",
        index + 1,
      );
      set_cell_value(rfq[index].spot_date, "SPOT_DATE", index + 1);
      set_cell_value(rfq[index].including_ref, "INCLUDE_REF", index + 1);

      if (ccypair_group === "NDF" && rfqMode !== "rfq booking view") {
        set_cell_value(rfq[index].fixing_ref, "FIXING_REF", index + 1);

        dispatch(removeHidenKey("CCYPAIR_GROUP"));
        dispatch(removeHidenKey("FIXING_REF"));
      } else if (
        (ccypair_group === "G10" || ccypair_group === "NONE") &&
        rfq[index].fixing_ref === "NO" &&
        rfqMode !== "rfq booking view"
      ) {
        set_cell_value(rfq[index].fixing_ref, "FIXING_REF", index + 1);

        dispatch(addHidenKey("CCYPAIR_GROUP"));
        dispatch(addHidenKey("FIXING_REF"));
      }
      if (rfqMode !== "rfq booking view") {
        set_cell_value(
          rfq[index].hadge_match_type,
          "HEDGE_MATCH_TYPE",
          index + 1,
        );
        dispatch(addHidenKey("HEDGE_MATCH_TYPE"));
      } else {
        dispatch(removeHidenKey("HEDGE_MATCH_TYPE"));

        set_cell_value(
          rfq[index].hadge_match_type !== undefined
            ? rfq[index].hadge_match_type
            : rfq[index].hedge_match_type,
          "HEDGE_MATCH_TYPE",
          index + 1,
        );
      }
      if (
        rfq[index].hadge_match_type === "MATCHED" ||
        rfq[index].hedge_match_type === "MATCHED"
      ) {
        // dispatch(removeHidenKey("HEDGE_TYPE"));
        // dispatch(removeHidenKey("HEDGE_VAL"));
        // dispatch(removeHidenKey("HEDGE_RATE"));
        // dispatch(removeHidenKey("HEDGE_DELTA"));
        // dispatch(addHidenKey("MM_HEDGE_TYPE"));
        // dispatch(addHidenKey("MM_HEDGE_VAL"));
        // dispatch(addHidenKey("MM_HEDGE_RATE"));
        // dispatch(addHidenKey("MM_HEDGE_DELTA"));
        // dispatch(addHidenKey("CLIENT_HEDGE_TYPE"));
        // dispatch(addHidenKey("CLIENT_HEDGE_RATE"));
        // dispatch(addHidenKey("CLIENT_HEDGE_VAL"));
        // dispatch(addHidenKey("CLIENT_HEDGE_DELTA"))
        set_cell_value(rfq[index].hedge_type, "HEDGE_TYPE", index + 1);
        set_cell_value(rfq[index].hedge_val, "HEDGE_VAL", index + 1);
        set_cell_value(rfq[index].hedge_rate, "HEDGE_RATE", index + 1);
        set_cell_value(rfq[index].hedge_delta, "HEDGE_DELTA", index + 1);

        set_cell_value(
          rfq[index].client_hedge_type,
          "CLIENT_HEDGE_TYPE",
          index + 1,
        );
        set_cell_value(
          rfq[index].client_hedge_val,
          "CLIENT_HEDGE_VAL",
          index + 1,
        );
        set_cell_value(
          rfq[index].client_hedge_rate,
          "CLIENT_HEDGE_RATE",
          index + 1,
        );
        set_cell_value(
          rfq[index].client_hedge_delta,
          "CLIENT_HEDGE_DELTA",
          index + 1,
        );

        set_cell_value(rfq[index].mm_hedge_type, "MM_HEDGE_TYPE", index + 1);
        set_cell_value(rfq[index].mm_hedge_val, "MM_HEDGE_VAL", index + 1);
        set_cell_value(rfq[index].mm_hedge_rate, "MM_HEDGE_RATE", index + 1);
        set_cell_value(rfq[index].mm_hedge_delta, "MM_HEDGE_DELTA", index + 1);
      } else if (
        rfq[index].hadge_match_type === "UNMATCHED" ||
        rfq[index].hedge_match_type === "UNMATCHED"
      ) {
        dispatch(addHidenKey("HEDGE_TYPE"));
        dispatch(addHidenKey("HEDGE_VAL"));
        dispatch(addHidenKey("HEDGE_RATE"));
        dispatch(addHidenKey("HEDGE_DELTA"));

        dispatch(removeHidenKey("MM_HEDGE_TYPE"));
        dispatch(removeHidenKey("MM_HEDGE_VAL"));
        dispatch(removeHidenKey("MM_HEDGE_RATE"));
        dispatch(removeHidenKey("MM_HEDGE_DELTA"));

        dispatch(removeHidenKey("CLIENT_HEDGE_TYPE"));
        dispatch(removeHidenKey("CLIENT_HEDGE_VAL"));
        dispatch(removeHidenKey("CLIENT_HEDGE_RATE"));
        dispatch(removeHidenKey("CLIENT_HEDGE_DELTA"));

        set_cell_value(rfq[index].hedge_type, "HEDGE_TYPE", index + 1);
        set_cell_value(rfq[index].hedge_val, "HEDGE_VAL", index + 1);
        set_cell_value(rfq[index].hedge_rate, "HEDGE_RATE", index + 1);
        set_cell_value(rfq[index].hedge_delta, "HEDGE_DELTA", index + 1);

        set_cell_value(
          rfq[index].client_hedge_type,
          "CLIENT_HEDGE_TYPE",
          index + 1,
        );
        set_cell_value(
          rfq[index].client_hedge_val,
          "CLIENT_HEDGE_VAL",
          index + 1,
        );
        set_cell_value(
          rfq[index].client_hedge_rate,
          "CLIENT_HEDGE_RATE",
          index + 1,
        );
        set_cell_value(
          rfq[index].client_hedge_delta,
          "CLIENT_HEDGE_DELTA",
          index + 1,
        );

        set_cell_value(rfq[index].mm_hedge_type, "MM_HEDGE_TYPE", index + 1);
        set_cell_value(rfq[index].mm_hedge_val, "MM_HEDGE_VAL", index + 1);
        set_cell_value(rfq[index].mm_hedge_rate, "MM_HEDGE_RATE", index + 1);
        set_cell_value(rfq[index].mm_hedge_delta, "MM_HEDGE_DELTA", index + 1);
      }
      set_cell_value(rfq[index].premium_val, "PREMIUM_VAL", index + 1);
      set_cell_value(rfq[index].premium_type, "PREMIUM_TYPE", index + 1);
      set_cell_value(rfq[index].client_price, "CLIENT_PRICE", index + 1);
      set_cell_value(rfq[index].market_price, "MARKET_PRICE", index + 1);
      set_cell_value(rfq[index].client_premium, "CLIENT_PREMIUM", index + 1);
      set_cell_value(rfq[index].market_premium, "MARKET_PREMIUM", index + 1);
      set_cell_value(
        rfq[index].net_client_price,
        "NET_CLIENT_PRICE",
        index + 1,
      );
      set_cell_value(
        rfq[index].net_market_price,
        "NET_MARKET_PRICE",
        index + 1,
      );

      if (
        rfq[index].option_type_text === "EXOTIC" &&
        rfqMode !== "rfq booking view"
      ) {
        dispatch(addHidenKey("DELTA"));
        dispatch(addHidenKey("HEDGE_AMOUNT"));
        dispatch(addHidenKey("PRICE_PCT_NOTIONAL"));
        dispatch(addHidenKey("VEGA"));
        dispatch(addHidenKey("BBG_MID_VOL"));
        dispatch(addHidenKey("NET_MARKET_PRICE"));
      }
      if (
        rfq[index].option_type_text === "VANILLA" &&
        rfqMode !== "rfq booking view"
      ) {
        dispatch(removeHidenKey("DELTA"));
        dispatch(removeHidenKey("HEDGE_AMOUNT"));
        dispatch(removeHidenKey("PRICE_PCT_NOTIONAL"));
        dispatch(removeHidenKey("VEGA"));
      }
      set_cell_value(rfq[index].premium_ccy, "PREMIUM_CCY", index + 1);
      set_cell_value(rfq[index].prem_ccy_type, "PREM_CCY_TYPE", index + 1);
      if (rfq[index].prem_ccy_type.toLowerCase() === "default") {
        dispatch(addHidenKey("THIRD_CCY"));
        dispatch(addHidenKey("FX_RATE"));
      } else if (rfq[index].prem_ccy_type.toLowerCase() === "third_ccy") {
        dispatch(removeHidenKey("THIRD_CCY"));
        dispatch(removeHidenKey("FX_RATE"));
      }
      set_cell_value(rfq[index].third_ccy, "THIRD_CCY", index + 1);
      set_cell_value(rfq[index].fx_rate, "FX_RATE", index + 1);
      set_cell_value(rfq[index].delta, "DELTA", index + 1);
      set_cell_value(rfq[index].hedge_amount, "HEDGE_AMOUNT", index + 1);
      set_cell_value(
        rfq[index].price_pct_notional,
        "PRICE_PCT_NOTIONAL",
        index + 1,
      );

      set_cell_value(rfq[index].vega, "VEGA", index + 1);
    }
  } else {
    if (rfqMode === "rfq view" || rfqMode == "") {
      dispatch(removeHidenKey("FIXING_REF"));
      dispatch(removeHidenKey("INCLUDE_REF"));
      dispatch(removeHidenKey("SPOT_REF"));
      dispatch(removeHidenKey("NEAR_SWAP"));
      dispatch(removeHidenKey("FAR_SWAP"));
      rfq_view_swp_hidden_rows.forEach((row) => {
        dispatch(addHidenKey(row));
      });
    } else if (rfqMode === "rfq booking view") {
      booking_mode_swp_hidden_rows.forEach((row) => {
        dispatch(addHidenKey(row));
      });
      const visible_swap_keys = [
        "CCY_PAIR",
        "DEAL_TYPE",
        "NEAR_DATE_TEXT",
        "FAR_DATE_TEXT",
        "NEAR_NOTIONAL",
        "FAR_NOTIONAL",
        "SWAP_CCY",
        "NEAR_DATE",
        "FAR_DATE",
        "SWAP_DIRECTION",
        "CLIENT_START_RATE",
        "MARKET_START_RATE",
        "CLIENT_SWAP_PTS",
        "MARKET_SWAP_PTS",
        "CLIENT_FAR_RATE",
        "MARKET_FAR_RATE",
      ];
      for (const key of visible_swap_keys) {
        dispatch(removeHidenKey(key));
      }
      for (
        let index = 0;
        index < jRef.current.jspreadsheet.headers.length - 1;
        index++
      ) {
        set_cell_value(rfq[index].pb_account, "PB_ACCOUNT", index + 1);
        set_cell_value(rfq[index].ccypair_group, "CCYPAIR_GROUP", index + 1);
        set_cell_value(rfq[index].request_for, "REQUEST_FOR", index + 1);
        set_cell_value(rfq[index].swap_direction, "SWAP_DIRECTION", index + 1);
        set_cell_value(
          rfq[index].client_start_rate,
          "CLIENT_START_RATE",
          index + 1,
        );
        set_cell_value(
          rfq[index].market_start_rate,
          "MARKET_START_RATE",
          index + 1,
        );
        set_cell_value(
          rfq[index].client_swap_pts,
          "CLIENT_SWAP_PTS",
          index + 1,
        );
        set_cell_value(
          rfq[index].market_swap_pts,
          "MARKET_SWAP_PTS",
          index + 1,
        );
        set_cell_value(
          rfq[index].client_far_rate,
          "CLIENT_FAR_RATE",
          index + 1,
        );
        set_cell_value(
          rfq[index].market_far_rate,
          "MARKET_FAR_RATE",
          index + 1,
        );
        set_cell_value(
          rfq[index].market_far_rate,
          "MARKET_FAR_RATE",
          index + 1,
        );
        set_cell_value(rfq[index].cut, "CUT", index + 1);
        set_cell_value(rfq[index].fixing_ref, "FIXING_REF", index + 1);
        set_cell_value(
          rfq[index].near_fixing_date,
          "NEAR_FIXING_DATE",
          index + 1,
        );
        set_cell_value(
          rfq[index].far_fixing_date,
          "FAR_FIXING_DATE",
          index + 1,
        );
      }
    }
    for (
      let index = 0;
      index < jRef.current.jspreadsheet.headers.length - 1;
      index++
    ) {
      set_cell_value(rfq[index].client_id, "REQUEST_FOR", index + 1);
      set_cell_value(rfq[index].ccy_pair, "CCY_PAIR", index + 1);
      set_cell_value(
        rfq[index].deal_type_text !== undefined
          ? rfq[index].deal_type_text
          : rfq[index].leg_type,
        "LEG_TYPE",
        index + 1,
      );
      set_cell_value(
        rfq[index].leg_type_text !== undefined
          ? rfq[index].leg_type_text
          : rfq[index].deal_type,
        "DEAL_TYPE",
        index + 1,
      );

      set_cell_value(rfq[index].option_type_text, "OPTION_TYPE", index + 1);
      set_cell_value(rfq[index].ccypair_group, "CCYPAIR_GROUP", index + 1);
      set_cell_value(rfq[index].pb_account, "PB_ACCOUNT", index + 1);
      set_cell_value(rfq[index].near_date_text, "NEAR_DATE_TEXT", index + 1);
      set_cell_value(rfq[index].far_date_text, "FAR_DATE_TEXT", index + 1);
      set_cell_value(rfq[index].near_notional, "NEAR_NOTIONAL", index + 1);
      set_cell_value(rfq[index].far_notional, "FAR_NOTIONAL", index + 1);
      set_cell_value(rfq[index].swap_ccy, "SWAP_CCY", index + 1);
      set_cell_value(rfq[index].near_date, "NEAR_DATE", index + 1);
      set_cell_value(rfq[index].far_date, "FAR_DATE", index + 1);
      set_cell_value(rfq[index].dates_in_rfq, "DATES_IN_RFQ", index + 1);
      set_cell_value(rfq[index].including_ref, "INCLUDE_REF", index + 1);
      set_cell_value(rfq[index].spot_ref, "SPOT_REF", index + 1);
      set_cell_value(rfq[index].near_swap, "NEAR_SWAP", index + 1);
      set_cell_value(rfq[index].far_swap, "FAR_SWAP", index + 1);
      set_cell_value(
        rfq[index].near_fixing_date,
        "NEAR_FIXING_DATE",
        index + 1,
      );
      set_cell_value(rfq[index].far_fixing_date, "FAR_FIXING_DATE", index + 1);
      set_cell_value(rfq[index].fixing_ref, "FIXING_REF", index + 1);
    }
  }

  assign_jexcel_data();
  addReadeadOnlyRowsInBookingModeBookedAndConfirmedRfq(
    jRef.current.jspreadsheet.headers,
    jRef.current.jspreadsheet.rows,
  );
};
