import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import * as clientActions from "../../../store/fi/fiClientsSlice";
import * as rfqPostTradeSlice from "../../../store/rfq/rfqPostTradeSlice";
import { useStyles } from "../../../Styles/fiStyles";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./select.css";

export default function SelectOption(values) {
  const { splitManner } = useSelector((state) => state.rfqPostTradeSlice);
  const rfqList = useSelector((state) => state.rfqSlice.rfq);
  const { rfqObject, thereIsConfo } = useSelector(
    (state) => state.rfqPostTradeSlice,
  );
  const rfqId = rfqObject?.rfq_id;
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [disabled, setDisabled] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const tradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit,
  );
  const newTradeStats = useSelector((state) => state.fiSlice.newTradeStats);
  const {
    postTradeSplitNumber,
    postTradeSplitArrValues,
    postTradeSplitArrPreValues,
  } = useSelector((state) => state.rfqPostTradeSlice);
  const { selectedClient } = useSelector((state) => state.fiClientsSlice);
  const [selectedValue, setSelectedValue] = useState("");
  const statusItem = useSelector((state) => state.rfqSlice.statusItem);
  const rfqIdStorage = sessionStorage.getItem("rfqId");
  const DEAL_TYPE = rfqObject?.rfq_table_data?.find(
    (field) => field[0] === "DEAL_TYPE",
  )[1][0];

  //! FI
  useEffect(() => {
    if (
      values.values.key === "netCashCurrency" &&
      newTradeStats.currency !== "" &&
      tradeToEdit === undefined
    ) {
      const tradeValue = values?.values?.values?.filter(
        (obj) => obj.id === newTradeStats.currency,
      );
      const obj = {
        value: tradeValue[0].id,
        key: values.values.key,
      };
      setValue(tradeValue[0].value);
      setSelectedValue(obj);
      dispatch(fiActions.editNewTradeForm(obj));
    }
  }, [newTradeStats]);

  // ! FX
  useEffect(() => {
    const rfqFromPreValues = postTradeSplitArrPreValues.filter(
      (rfq) => rfq.rfq_id === rfqIdStorage,
    );
    if (splitManner !== rfqFromPreValues[0]?.split_manner) {
      if (values.values.key !== "postTradeSplitNumber") {
        setValue("");
        setSelectedValue("");
        setInputValue("");
      }
    } else {
      if (values.values.key === "postTradeSplitNumber") {
        const obj = {
          value: rfqFromPreValues[0].split_number,
          key: "postTradeSplitNumber",
        };
        dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
        setSelectedValue(obj);
        setValue(`${rfqFromPreValues[0].split_number}`);
      } else {
        if (values.values.key === "postTradeClientSplitId") {
          setValue(
            postTradeSplitArrValues[`${values.values.id}`]
              ?.postTradeClientSplitId,
          );
          setSelectedValue({
            value:
              postTradeSplitArrValues[`${values.values.id}`]
                ?.postTradeClientSplitId,
            key: values.values.key,
          });
        } else if (values.values.key === "postTradePmSplitId") {
          setValue(
            postTradeSplitArrValues[`${values.values.id}`]?.postTradePmSplitId,
          );
          setSelectedValue({
            value:
              postTradeSplitArrValues[`${values.values.id}`]
                ?.postTradePmSplitId,
            key: values.values.key,
          });
        }
      }
    }
  }, [splitManner]);

  useEffect(() => {
    if (values.values.key === "postTradeSplitNumber") {
      const obj = {
        value: postTradeSplitNumber,
        key: values.values.key,
      };
      setSelectedValue(obj);
      setValue(`${postTradeSplitNumber}`);
    }
  }, [postTradeSplitNumber]);

  useEffect(() => {
    if (values.values.type !== "new") {
      const res = rfqList.filter((r) => r.rfq_id === +rfqId);
      if (res[0]?.status === "Booked" || thereIsConfo) {
        setDisabled(true);
      }
      if (
        tradeToEdit !== undefined &&
        values.values.key !== "client" &&
        values.values.key !== "trader" &&
        values.values.key !== "ourSide" &&
        values.values.key !== "splitNumber" &&
        values.values.key !== "clientSplitId" &&
        values.values.key !== "postTradeClientSplitId" &&
        values.values.key !== "postTradePmSplitId" &&
        values.values.key !== "postTradeSplitNumber" &&
        values.values.key !== "traderIdClientBlotter" &&
        values.values.key !== "netCashCurrency" &&
        values.values.key !== "ssi" &&
        values.values.key !== "company"
      ) {
        const tradeValue = values?.values?.values?.filter(
          (obj) => obj.value === tradeToEdit[`${values.values.key}`],
        );
        const obj = {
          value: tradeValue[0].id,
          key: values.values.key,
        };
        setValue(tradeValue[0].value);
        setSelectedValue(obj);
        dispatch(fiActions.editNewTradeForm(obj));
      } else if (
        tradeToEdit !== undefined &&
        (values.values.key === "client" || values.values.key === "trader")
      ) {
        const tradeValue = values?.values?.values?.filter(
          (obj) => obj.value === tradeToEdit[`${values.values.key}`],
        );
        if (
          (values.values.key === "client" && tradeValue[0]?.id !== 858) ||
          (values.values.key === "trader" && tradeValue[0]?.id !== 9)
        ) {
          const obj = {
            value: tradeValue[0]?.id,
            key: values.values.key,
          };
          setValue(tradeValue[0]?.value);
          setSelectedValue(obj);
          dispatch(fiActions.editNewTradeForm(obj));
        }
      } else if (tradeToEdit !== undefined && values.values.key === "ourSide") {
        const obj = {
          value: tradeToEdit.side,
          key: values.values.key,
        };
        setValue(tradeToEdit.side);
        setSelectedValue(obj);
        dispatch(fiActions.editNewTradeForm(obj));
      } else if (
        tradeToEdit !== undefined &&
        values.values.key === "splitNumber"
      ) {
        const obj = {
          value: 2,
          key: values.values.key,
        };
        setValue("2");
        setSelectedValue(obj);
      } else if (
        postTradeSplitArrValues.length > 0 &&
        values.values.key === "postTradeClientSplitId"
      ) {
        setValue(
          postTradeSplitArrValues[`${values.values.id}`]
            ?.postTradeClientSplitId,
        );
        setSelectedValue({
          value:
            postTradeSplitArrValues[`${values.values.id}`]
              ?.postTradeClientSplitId,
          key: values.values.key,
        });
      } else if (
        postTradeSplitArrValues.length > 0 &&
        values.values.key === "postTradePmSplitId"
      ) {
        setValue(
          postTradeSplitArrValues[`${values.values.id}`]?.postTradePmSplitId,
        );
        setSelectedValue({
          value:
            postTradeSplitArrValues[`${values.values.id}`]?.postTradePmSplitId,
          key: values.values.key,
        });
      } else if (
        postTradeSplitArrValues.length > 0 &&
        values.values.key === "postTradeSplitNumber"
      ) {
        setValue(`${postTradeSplitNumber}`);
        setSelectedValue({
          value: postTradeSplitNumber,
          key: values.values.key,
        });
      } else if (values.values.key === "ssi") {
        const obj = {
          value: selectedClient.ssi,
          key: values.values.key,
        };
        setValue(selectedClient.ssi);
        setSelectedValue(obj);
        dispatch(clientActions.editNewClientValues(obj));
      } else if (values.values.key === "company") {
        const obj = {
          value: selectedClient.company,
          key: values.values.key,
        };
        setValue(selectedClient.company);
        setSelectedValue(obj);
        dispatch(clientActions.editNewClientValues(obj));
      } else if (
        tradeToEdit !== undefined &&
        values.values.key === "netCashCurrency"
      ) {
        const tradeValue = values?.values?.values?.filter(
          (obj) => obj.value === tradeToEdit.net_cash_currency,
        );
        const obj = {
          value: tradeValue[0].id,
          key: values.values.key,
        };

        setValue(tradeValue[0].value);
        setSelectedValue(obj);
        dispatch(fiActions.editNewTradeForm(obj));
      }
    }
  }, []);

  const handleChange = (newValue) => {
    // newValue.split("[")[0];
    let obj;
    if (values.values.key === "client") {
      setValue(newValue.split("[")[0]);
      // console.log(values.values.values);

      const res = values?.values?.values?.filter(
        (v) => `${v.value} [${v.firmName}]` === newValue,
      )[0];

      obj = {
        value: res.id,
        key: values.values.key,
        id: values.values.id,
      };
      setSelectedValue(obj);
    } else if (values.values.key !== "client") {
      setValue(newValue);
      const res = values?.values?.values?.filter((v) => v.value === newValue)[0];
      obj = {
        value:
          values.values.key !== "splitNumber" &&
          values.values.key !== "postTradeSplitNumber"
            ? res.id
            : newValue,
        key: values.values.key,
        id: values.values.id,
      };
      setSelectedValue(obj);
    }

    if (
      values.values.key !== "splitNumber" &&
      values.values.key !== "clientSplitId" &&
      values.values.key !== "postTradeClientSplitId" &&
      values.values.key !== "postTradeSplitNumber" &&
      values.values.key !== "postTradePmSplitId" &&
      values.values.key !== "ssi" &&
      values.values.key !== "company"
    ) {
      dispatch(fiActions.editNewTradeForm(obj));
    } else if (values.values.key === "splitNumber") {
      dispatch(fiActions.setSplitTradeForm(obj));
    } else if (values.values.key === "postTradeSplitNumber") {
      dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
    } else if (values.values.key === "clientSplitId") {
      dispatch(fiActions.setSplitArrValues(obj));
    } else if (values.values.key === "postTradePmSplitId") {
      dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(obj));
    } else if (values.values.key === "postTradeClientSplitId") {
      dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(obj));
      const pmObj = {
        value: values?.values?.values?.filter(
          (client) => client.id === newValue,
        )[0].pm,
        key: values.values.key,
        id: values.values.id,
      };
      dispatch(rfqPostTradeSlice.setPostTradesSplitArrPmValues(pmObj));
    } else if (values.values.key === "ssi") {
      if (newValue !== "FDNI") {
        let obj1 = {
          key: "agentName",
          value: "",
        };
        let obj2 = {
          key: "agentName",
          value: "",
        };
        let obj3 = {
          key: "agentName",
          value: "",
        };

        let obj4 = {
          key: "agentName",
          value: "",
        };
        dispatch(clientActions.editNewClientValues(obj1));
        dispatch(clientActions.editNewClientValues(obj2));
        dispatch(clientActions.editNewClientValues(obj3));
        dispatch(clientActions.editNewClientValues(obj4));
      } else if (newValue === "FDNI") {
        let obj1 = {
          key: "account",
          value: "",
        };
        dispatch(clientActions.editNewClientValues(obj1));
      }
      dispatch(clientActions.editNewClientValues(obj));
    } else if (values.values.key === "company") {
      dispatch(
        clientActions.editNewClientValues({ key: "company", value: newValue }),
      );
    }

    return;
  };
  return (
    <div>
      <Autocomplete
        disabled={
          disabled ||
          (statusItem === "Confirmed" &&
            (values.values.key === "postTradeSplitNumber" ||
              values.values.key === "postTradePmSplitId" ||
              values.values.key === "postTradeClientSplitId")) ||
          (statusItem === "Booked" &&
            (values.values.key === "postTradeSplitNumber" ||
              values.values.key === "postTradePmSplitId" ||
              values.values.key === "postTradeClientSplitId")) ||
          (statusItem === "Confirmed & Booked" &&
            (values.values.key === "postTradeSplitNumber" ||
              values.values.key === "postTradePmSplitId" ||
              values.values.key === "postTradeClientSplitId"))
        }
        freeSolo
        clearOnEscape
        autoHighlight
        selectOnFocus
        className={clsx(classes.autocomplete)}
        value={value}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        
        // className={"selectAutoComplete"}
        options={values?.values?.values?.map((v) => {
          if (values.values.key === "client") {
            return `${v.value} [${v.firmName}]`;
          } else {
            return `${v.value}`;
          }
        })}
        renderInput={(params) => (
          <TextField
            className={clsx(
              classes.selectOptionBox,
              "selectAutoComplete",
              {
                [classes.selectOptionBoxPostTrades]:
                  values.values.key === "postTradePmSplitId" ||
                  values.values.key === "postTradeClientSplitId",
              },
              {
                "clients-dialog":
                  values.values.key === "company" ||
                  values.values.key === "ssi",
              },
            )}
            {...params}
            label={values?.values?.label}
            variant="outlined"
            SelectProps={{
              native: true,
              maxwidth: "10px",
              open: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </div>
  );
}
