import { makeStyles, createTheme } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  orderBookTypography: (props) => ({
    backgroundColor: "#2B334C",
    color:
      props.orderBook === "BXE"
        ? "#2692FF"
        : props.orderBook === "DXE"
        ? "#8363FF"
        : null,
    borderRadius: "4px",
    width: "100px",
    height: "30px",
    fontWeight: "bold",
    textAlign: "center", // Centers the text horizontally
    display: "flex", // Use flexbox for centering
    alignItems: "center", // Centers the text vertically
    justifyContent: "center", // Centers the text horizontally
  }),

  customSwitch: (props) => ({

    "& .MuiSwitch-switchBase": {
    
      color: "#FFFFFF", // White color for the circle (switch thumb)
      "&.Mui-checked": {
        color: "#FFFFFF", // Keep the circle white when checked
        "& + .MuiSwitch-track": {
          backgroundColor: props.orderBookSwitcher ? "#8363FF" : "#2692FF",
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: props.orderBookSwitcher ? "#8363FF" : "#2692FF",
      opacity: 1,
    },
  }),
  tabs: {
    // Dark background matching the image
    color: "#FFFFFF", // White text for the tab labels
    borderBottom: "1px solid #686B76", // Border line under the tabs
    flexGrow: 1,
  },
  tab: {
    textTransform: "none", // Keep text as is (no uppercase)
    fontSize: "14px", // Adjust the font size
    // Bold text for the labels
    minWidth: "120px", // Adjust width as needed
    color: "#A0A0A0", // Light gray for inactive tabs
    "&.Mui-selected": {
      color: "#FFFFFF", // White color for the active tab
      backgroundColor: "#171717",
    },
    "&:not(:last-child)": {
      marginRight: "4px", // Add spacing between tabs (only to the right of each tab, except the last one)
    },
    borderRadius: "4px 4px 0px 0px",
    backgroundColor: "#2D2D2D",
    width: 180,
    // borderBottom: "1px solid #A0A0A0",
  },
  indicator: {
    backgroundColor: "#4B9DFF", // Blue indicator line for the active tab
    height: "4px",
  },

  btn: {
    textTransform: "capitalize",
    fontWeight: 400,
    height: 40,
    width: 70,
    marginRight: 15,
  },
  // Specific styles for the Send button
  sendBtn: {
    backgroundColor: "transparent",
    border: "1px solid #22D865",
    color: "#22D865",
    "&:hover": {
      backgroundColor: "#1DBA5B",
      color: "white",
      border: "1px solid white",
    },
  },
  amendBtn: {
    backgroundColor: "transparent",
    border: "1px solid #4CAEF8",
    color: "#4CAEF8",
    "&:hover": {
      backgroundColor: "#3A9DE0",
      color: "white",
      border: "1px solid white",
    },
  },
  cancelBtn: {
    backgroundColor: "transparent",
    border: "1px solid #FF4E32",
    color: "#FF4E32",
    "&:hover": {
      backgroundColor: "#CC3E28",
      color: "white",
      border: "1px solid white",
    },
  },
  deleteBtn: {
    backgroundColor: "transparent",
    border: "1px solid #F7B24C",
    color: "#F7B24C",

    "&:hover": {
      backgroundColor: "#E7A040",
      color: "white",
      border: "1px solid white",
    },
  },
  searchInput: {
    font: "normal normal normal 16px/20px Inter",
    color: "white",
    border: "1px solid #686B76",
    borderRadius: "4px",
    height: "32px",
    width: "180px",
    paddingLeft: "10px",
    "& .MuiSvgIcon-root": {
      color: "#2692ff",
      fontSize: "25px",
    },
  },
  csvIcon: {
    fill: "#2692FF",
    width: "25px",
    height: "25px",
    marginTop:"30px",
    marginRight:"-15px"
  },
}));

export const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif", // Use 'Inter' as the font family
    fontSize: 16, // Base font size in pixels
    fontWeight: "normal", // Font weight set to normal
    lineHeight: "24px", // Line height in pixels
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        // Apply the font family, size, weight, and line height globally
        body: {
          fontFamily: "'Inter', sans-serif",
          fontSize: "16px",
          fontWeight: "normal",
          lineHeight: "24px",
        },
        "*": {
          fontFamily: "'Inter', sans-serif",
          fontSize: "16px",
          fontWeight: "normal",
          lineHeight: "24px",
        },
      },
    },
  },
});
