import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  Switch,
  InputBase,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GenericTable } from "../genericTable/GenericTable";
import { useStyles } from "../../style/style";
import { submitTrades, cancelTrades, amendTrades } from "../../api/apiCalls";
import { useDispatch } from "react-redux";
import { dateFormatter } from "../genericTable/utils/valueFormatters";
import { formatNumber } from "../genericTable/utils/numberFormatter";
import SearchIcon from "@material-ui/icons/Search";
import { ReactComponent as DownloadCsv } from "../../../../assets/csv-download.svg";
import { ReactComponent as Refresh } from "../../../../assets/refresh.svg";
import { fetchTrades } from "../../api/apiCalls";
import moment from "moment";

export const Workspace = ({
  orderBook,
  tab,
  rowData,
  setRowData,
  tradeType,
  setTradeType,
  filteredData,
  setFilteredData,
  selectedTab,
}) => {
  const classes = useStyles({});
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");

  const handleSwitchOrderBook = (e) => {
    const isChecked = e.target.checked;
    setTradeType(isChecked);
  };

  const columnDefs = [
    { headerCheckboxSelection: true,
       checkboxSelection: 
       true, 
       maxWidth: 40 },
    {
      headerName: "Buyer",
      field: "buyer",
      filterParams: {
        maxNumConditions: 1,
      },
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    {
      headerName: "Buyer Account",
      field: "buyerAccount",
      minWidth: 110,
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    {
      headerName: "Seller",
      field: "seller",
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    {
      headerName: "Seller Account",
      field: "sellerAccount",
      minWidth: 110,
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    {
      headerName: "BBG Code",
      field: "bbgCode",
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    {
      headerName: "Quantity",
      field: "quantity",
      valueFormatter: formatNumber,
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    {
      headerName: "Price",
      field: "price",
      minWidth: 100,
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    {
      headerName: "Trade Date",
      field: "trade_date",
      minWidth: 120,
      valueFormatter: dateFormatter,
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    {
      headerName: "Settlement Date",
      field: "settlementDate",
      minWidth: 120,
      valueFormatter: dateFormatter,
      editable: (params) => {
        return (
          params.data.status === "New" ||
          params.data.status === "Error" ||
          params.data.batsResponse === "Rejected" ||
          params.data.status === "Replaced"
        );
      },
    },
    { headerName: "Trade ID", field: "tradeId" },
    { headerName: "Basket ID", field: "basketId" },
    {
      headerName: "Status",
      field: "status",

      cellRenderer: (params) => {
        const { value, data } = params;
        const errorMessage = data?.error;

        return (
          <Tooltip
            title={
              value?.toLowerCase() === "error" && errorMessage ? (
                <Typography variant="body2" color="white">
                  {errorMessage}
                </Typography>
              ) : (
                ""
              )
            }
            arrow
          >
            <span
              style={{
                color:
                  value?.toLowerCase() === "sent"
                    ? "#22D865"
                    : value?.toLowerCase() === "new"
                      ? "#1CD3FF"
                      : value?.toLowerCase() === "error"
                        ? "#FF4E32"
                        : value.toLowerCase() === "pending" ||
                          value.toLowerCase() === "cancel"
                          ? "#F7B24C"
                          : "white",
              }}
            >
              {value}
            </span>
          </Tooltip>
        );
      },
    },
    {
      flex: 1.2,
      minWidth: 150,
      headerName: "Exec Time",
      field: "execTime",
      cellStyle: () => {
        return { color: "#989EA8" };
      },
    },
    { headerName: "BATS ID", field: "batsId" },
    {
      headerName: "BATS Response",
      field: "batsResponse",
      cellRenderer: (params) => {
        const { value, data } = params;
        const errorMessage = data?.error;
        return (
          <Tooltip
            title={
              value === "Rejected" || (value === "Declined" && errorMessage) ? (
                <Typography variant="body2" color="white">
                  {errorMessage}
                </Typography>
              ) : (
                ""
              )
            }
            arrow
          >
            <span
              style={{
                color: value === "Accepted" ? "#22D865" : "#FF4E32",
              }}
            >
              {value}
            </span>
          </Tooltip>
        );
      },
      minWidth: 110,
    },
  ];
  const [isUpdate, setIsUpdate] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const [sendButtonStatus, setSendButtonStatus] = useState(false);
  const [amendButtonStatus, setAmendButtonStatus] = useState(false);
  const dispatch = useDispatch();

  const handleSend = async () => {
    window.dispatchEvent(new Event("stopEditing"));
    const ids = selectedRows.map((trade) => trade.id);
    const filteredTrades = filteredData.filter((trade) => ids.includes(trade.id));
    const isUpdate = filteredData.some(trade => trade.tradeId != null);
    try {
      const validateRes = await submitTrades(
        filteredTrades,
        orderBook,
        dispatch,
        setFilteredData,
        setSelectedRows,
        tradeType,
        isUpdate
      );
      if (validateRes?.success) {
        console.log("send to python", { validateRes });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = async () => {
    const ids = selectedRows.map((trade) => trade.id);
    const filteredTrades = filteredData.filter((trade) => ids.includes(trade.id));
    try {
      const validateRes = await cancelTrades(
        tab === "history" ? selectedRows : filteredTrades,
        orderBook,
        dispatch,
        setFilteredData,
        setSelectedRows,
        tradeType
      );
      if (validateRes?.success) {
        console.log("send to python", { validateRes });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAmend = async () => {
    const ids = selectedRows.map((trade) => trade.id);
    const filteredTrades = filteredData.filter((trade) => ids.includes(trade.id));
    try {
      const validateRes = await amendTrades(
        filteredTrades,
        orderBook,
        dispatch,
        setFilteredData,
        setSelectedRows,
        tradeType
      );

      if (validateRes?.success) {
        console.log("send to python", { validateRes });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (value.trim() === "") {
      setFilteredData(rowData);
    } else {
      console.log(value,rowData)
      // if()
      if(value && rowData !== undefined && rowData.length > 0){
        const lowerCaseTerm = value.toLowerCase();
        const results = rowData.filter((item) =>
          Object.values(item).some(
            (value) =>
              value !== null &&
              value.toString().toLowerCase().includes(lowerCaseTerm)
          )
        );
        setFilteredData(results);
      }

    }
  };

  const handleDateFilter = (e) => {
    const value = e.target.value;
    setDate(value);

  };

  const handleDownload = async () => {
    try {
      if (tab === "history") {
       downloadJSONAsCSV(selectedRows);
      } else {
        downloadJSONAsCSV(filteredData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadJSONAsCSV = async (rowData) => {
    if (!Array.isArray(rowData) || rowData.length === 0) {
      console.error("Invalid rowData: Expected a non-empty array");
      return;
    }
  
    const orderedKeys = [
      "trade_report_id",
      "isin",
      "exchange",
      "currency",
      "buyer",
      "buyerAccount",
      "seller",
      "sellerAccount",
      "bbgCode",
      "quantity",
      "price",
      "settlementDate",
      "trade_date",
      "execTime",
      "batsId",
      "batsResponse",
      "venue"
    ];
  
    const keysFromFunction = [
      "buyer",
      "buyerAccount",
      "seller",
      "sellerAccount",
      "bbgCode",
      "quantity",
      "price",
      "settlementDate",
      "execTime",
      "batsId",
      "batsResponse",
      "venue",
      "trade_report_id",
      "isin",
      "exchange",
      "currency",
      "trade_date",
    ];
  
    const formattedDate = moment().format("YYYYMMDD");
    const filename = `${orderBook}_${rowData[0].buyer}--${rowData[0].seller}--${tab}_${formattedDate}.csv`;
  
    const csvRows = [orderedKeys.join(",")];
  
    // Convert JSON to CSV, ensuring correct order and handling missing keys
    rowData.forEach((item) => {
      const values = orderedKeys.map((key) => {
        if (!keysFromFunction.includes(key)) return ""; // If key is missing, fill with null
  
        let value = item[key];
  
        if (typeof value === "string") {
          value = value.replace(/"/g, ""); // Remove double quotes
        }
  
        return value !== undefined && value !== null ? value : ""; // Default missing values to "null"
      });
  
      csvRows.push(values.join(",")); // Join values without extra line breaks
    });
  
    // Create a CSV content blob
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
  
    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
  
    // Trigger the download
    document.body.appendChild(link);
    link.click();
  
    // Cleanup
    document.body.removeChild(link);
  };
  

  const totalNotionalSum = (data) => {
    if (data !== undefined && data.length > 0) {
      return data
        .reduce((sum, trade) => sum + parseFloat(trade.quantity) * parseFloat(trade.price), 0)
        .toFixed(0) // ✅ Rounds to the nearest whole number (no decimals)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // ✅ Adds comma formatting
    }
    return 0;
  };
  
  const handleClearTable = () => {
    window.location.reload();
   }
  
  useEffect(() => {
    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      const filterBatsResponse = selectedRows.filter(
        (row) => row.batsResponse === "Rejected"
      );
      if (filterBatsResponse.length > 0) {
        setSendButtonStatus(true);
      } else {
        const filter = selectedRows.filter(
          (row) =>
            row.status.toLowerCase() !== "new" &&
            row.status !== "Error"
        );
        setSendButtonStatus(filter.length === 0); // True if all rows are "new"
      }
    } else {
      setSendButtonStatus(false); // False if selectedRows is empty or invalid
    }
    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      const filterBatsResponse = selectedRows.filter(
        (row) => row.batsResponse === "Rejected"
      );
      if (filterBatsResponse.length > 0) {
        setAmendButtonStatus(false);
      } else {
        const filter = selectedRows.filter(
          (row) => row.status.toLowerCase() !== "sent" &&
            row.status !== "Replaced"
        );
        setAmendButtonStatus(filter.length === 0); // True if all rows are "new"
      }
    } else {
      setAmendButtonStatus(false); // False if selectedRows is empty or invalid
    }
  }, [selectedRows]);

  const handleHeader = () => {
    if (tab === "workspace") {
      return (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          {sendButtonStatus ? (
            <Grid>
             
            <Button
              className={`${classes.btn} ${classes.sendBtn}`}
              onClick={handleSend}
            >
              Send
            </Button>
           </Grid>
          ) : amendButtonStatus ? (
            <Grid direction="row" justifyContent="flex=start">
              {/* <Button
                className={`${classes.btn} ${classes.amendBtn}`}
                onClick={handleAmend}
              >
                Amend
              </Button> */}
              <Button
                className={`${classes.btn} ${classes.cancelBtn}`}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
          ) : (
            <Grid></Grid>
          )}
          {selectedRows.length === 0 && rowData.length === 0 ? (
            <Grid>
              <Typography variant="h5">
                Paste new trades using CTRL + V
              </Typography>
            </Grid>
          ) : (
            <Grid></Grid>
          )}
          <Grid style={{ display: "flex", gap: "16px" }}>
            <Grid>
            <Button
             className={`${classes.deleteBtn} ${classes.deleteBtn}`}
             onClick={handleClearTable}
           >
             Clear
           </Button>
            </Grid>
            <Grid>
              
              <Grid style={{ display: "flex", gap: "4px" }}> 
              <Typography style={{color:"#989EA8"}}>
                Total Trades: 
              </Typography>
              <Typography>{filteredData?.length} ({`${totalNotionalSum(filteredData)}`})</Typography>
              </Grid>
              <Grid style={{ display: "flex", gap: "4px" }}>
              <Typography style={{color:"#989EA8"}}>Total Trades Selection:
              </Typography>
              <Typography>
              {selectedRows?.length} ({`${totalNotionalSum(selectedRows)}`})
              </Typography>
              </Grid>
             
            </Grid>
            <Grid>
              <InputAdornment>
                <IconButton onClick={handleDownload}>
                  <DownloadCsv className={classes.csvIcon} />
                </IconButton>
              </InputAdornment>
            </Grid>

            <Grid>
              <InputBase
                className={classes.searchInput}
                variant="outlined"
                value={search}
                onChange={handleSearch}
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>

            <Grid style={{ display: "flex" }}>
              <Typography
                variant="h5"
                style={{
                  marginTop: "5px",
                  color: !tradeType ? "#FFFFFF" : "#989EA8", // Highlight the selected label
                  fontWeight: !tradeType ? "bold" : "normal", // Bold text for the active label
                }}
              >
                Cash
              </Typography>
              <Switch
                checked={tradeType}
                onChange={handleSwitchOrderBook}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
                className={classes.customSwitch}
              />
              <Typography
                variant="h5"
                style={{
                  marginTop: "5px",
                  color: tradeType ? "#FFFFFF" : "#989EA8", // Highlight the selected label
                  fontWeight: tradeType ? "bold" : "normal", // Bold text for the active label
                }}
              >
                EFP
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {

      return (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >

          {amendButtonStatus ?
            <Grid>
              <Grid direction="row" justifyContent="flex-start">
                {/* <Button
                className={`${classes.btn} ${classes.amendBtn}`}
                onClick={handleAmend}
              >
                Amend
              </Button> */}
                <Button
                  className={`${classes.btn} ${classes.cancelBtn}`}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid> : <Grid></Grid>}


          <Grid></Grid>

          <Grid>
            <Grid style={{ display: "flex", gap: "10px" }}>
              <Grid>
                <InputAdornment>
                  <IconButton onClick={handleDownload}>
                    <DownloadCsv className={classes.csvIcon} />
                  </IconButton>
                </InputAdornment>
              </Grid>
              <Grid>
              <InputBase
                style={{
                  marginRight: "10px",
                }}
                name="date"
                variant="outlined"
                type="date"
                className={classes.searchInput}
                onChange={handleDateFilter}
              />
                <InputBase
                  className={classes.searchInput}
                  variant="outlined"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      );
    }
    
  };

  // useEffect(() => {
  //   if (tab === "workspace") {
  //     setRowData([...responseData]);
  //   }
  //   console.log("mounted");
  //   return () => {
  //     console.log("component unmounted");
  //     setSelectedRows([]);
  //   };
  // }, [tab]);

  return (
    <>
      {handleHeader()}
      <GenericTable
        rowData={rowData}
        setRowData={setRowData}
        columnDefs={columnDefs}
        isWorkspace={tab === "workspace"}
        isHistory={tab === "history"}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        orderBook={orderBook}
        tradeType={tradeType}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        search={search}
        isUpdate={isUpdate}
        date={date}
      />
    </>
  );
};
