import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, styled } from "@material-ui/core";
import { Dialog, Grid, Typography, IconButton, Divider } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "../../../../Styles/rfqPostTradeStyles";
import SelectOption from "../../../fi/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import * as rfqPostTradeSlice from "../../../../store/rfq/rfqPostTradeSlice";
import NumberInput from "../../../fi/numberInput/NumberInput";
import PostTradeSplitCalculator from "./PostTradeSplitCalculator";
import * as actionSnackBar from "../../../../store/snackbar/action";
import clsx from "clsx";

export default function RfqSplitModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { open, type, handleClose, setSplitted, confirmed } = props;
  const rfq_object = useSelector((state) => state.rfqPostTradeSlice.rfqObject);
  const [clientsListObj, setClientsListObj] = useState({});
  const [rfqDeltas, setRfqDeltas] = useState();
  const rfq = useSelector((state) => state.rfqSlice.rfqId.items);
  const rfqList = useSelector((state) => state.rfqSlice.rfq);
  const [numberOfLegs, setNumberOfLegs] = useState();
  const [numberOfDeltas, setNumberOfDeltas] = useState();

  const {
    postTradeSplitArrPreValues,
    postTradeSplitArrValues,
    currentMarketMaker,
    allMakorHSBCClientAndMarketMakers,
    makorClients,
    oscarGrussClients,
    makorSCBClients,
    postTradesSplitArrPmValues,
    postTradeSplitNumber,
    postTradeNearNotionalLeftToSplit,
    postTradeFarNotionalLeftToSplit,
    postTradeAmountLeftToSplit,
    postTradeAmountLeftToNotionalSplit,
    postTradeAmountLeftToDeltaNotionalSplit,
    sd,
  } = useSelector((state) => state.rfqPostTradeSlice);

  const rfqId = sessionStorage.getItem("rfqId");
  const [splitManner, setSplitManner] = useState(
    postTradeSplitArrValues.length === 0
      ? "percentage"
      : postTradeSplitArrValues[0].hasOwnProperty("Leg1NotionalSplitOption")
      ? "notional"
      : "percentage"
  );
  const DEAL_TYPE = rfq_object?.rfq_table_data?.find(
    (field) => field[0] === "DEAL_TYPE"
  )[1][0];
  PostTradeSplitCalculator();

  const splitOptionObj = {
    label: "Allocation %",
    key: "postTradeSplitOption",
  };

  const splitPmArrInit = [];
  const splitNumArr = [];
  for (let index = 1; index <= postTradeSplitNumber; index++) {
    splitNumArr.push(`obj ${index}`);
    splitPmArrInit.push({
      id: "no options",
      value: "no options",
    });
  }

  const splitValues = [];
  for (let index = 2; index < 11; index++) {
    splitValues.push({ id: index, value: index });
  }
  const splitNumObj = {
    label: "Number of splits",
    values: splitValues,
    key: "postTradeSplitNumber",
    type,
  };

  useEffect(() => {
    if (currentMarketMaker === "MAKOR-RBS") {
      const clientsList = [];
      if (sd) {
        const oscarClientListName = oscarGrussClients.map((key) => {
          return key.name;
        });
        let oscarClientListUnique = [...new Set(oscarClientListName)];
        oscarClientListUnique.map((client, index) => {
          const pmList = [];
          const fixedOgClientList = oscarGrussClients.filter(
            (clientSource) => clientSource.name === client
          );
          fixedOgClientList.map((obj) => pmList.push(obj.pm_name));
          return clientsList.push({
            id: `${client}`,
            value: client,
            pm: pmList,
          });
        });
      } else {
        const makorClientListName = makorClients.map((key) => {
          return key.name;
        });
        let makorClientListUnique = [...new Set(makorClientListName)];

        makorClientListUnique.map((client, index) => {
          const pmList = [];
          const fixedMakorClientList = makorClients.filter(
            (clientSource) => clientSource.name === client
          );
          fixedMakorClientList.map((obj) => pmList.push(obj.pm_name));
          return clientsList.push({
            id: `${client}`,
            value: client,
            pm: pmList,
          });
        });
      }

      const obj = {
        label: "Client",
        values: clientsList,
        key: "postTradeClientSplitId",
        type,
      };
      setClientsListObj(obj);
    } else if (currentMarketMaker === "OG-RBS" ) {
      const clientsList = [];
      const oscarClientListName = oscarGrussClients.map((key) => {
        return key.name;
      });
      let oscarClientListUnique = [...new Set(oscarClientListName)];
      oscarClientListUnique.map((client, index) => {
        const pmList = [];
        const fixedOgClientList = oscarGrussClients.filter(
          (clientSource) => clientSource.name === client
        );
        fixedOgClientList.map((obj) => pmList.push(obj.pm_name));
        return clientsList.push({
          id: `${client}`,
          value: client,
          pm: pmList,
        });
      });

      const obj = {
        label: "Client",
        values: clientsList,
        key: "postTradeClientSplitId",
      };
      setClientsListObj(obj);
    } else if (currentMarketMaker === "MAKOR-HSBC") {
      const clientsList = [];
      const makorHSBCClientListName = allMakorHSBCClientAndMarketMakers.map(
        (key) => {
          return key.name;
        }
      );
      let makorHSBCClientListUnique = [...new Set(makorHSBCClientListName)];

      makorHSBCClientListUnique.map((client, index) => {
        const pmList = [];
        const fixedMakorHSBCClientList =
          allMakorHSBCClientAndMarketMakers.filter(
            (clientSource) => clientSource.name === client
          );
        fixedMakorHSBCClientList.map((obj) => pmList.push(obj.pm_name));
        return clientsList.push({
          id: `${client}`,
          value: client,
          pm: pmList,
        });
      });

      const obj = {
        label: "Client",
        values: clientsList,
        key: "postTradeClientSplitId",
      };
      setClientsListObj(obj);
    }else if(currentMarketMaker == "MAKOR-SCB"){
      const clientsList = [];
      const scbClientListName = makorSCBClients.map((key) => {
        return key.name;
      });
      let scbClientListUnique = [...new Set(scbClientListName)];
      scbClientListUnique.map((client, index) => {
        const pmList = [];
        const fixedScbClientList = makorSCBClients.filter(
          (clientSource) => clientSource.name === client
        );
        fixedScbClientList.map((obj) => pmList.push(obj.pm_name));
        return clientsList.push({
          id: `${client}`,
          value: client,
          pm: pmList,
        });
      });

      const obj = {
        label: "Client",
        values: clientsList,
        key: "postTradeClientSplitId",
      };
      setClientsListObj(obj);
    }
  }, [currentMarketMaker, sd]);

  useEffect(() => {
    const res =
      open && rfqList.filter((r) => Number(r.rfq_id) === Number(rfqId));
    if (res[0]?.status !== "Booked") {
      const rfqRes =
        open &&
        postTradeSplitArrPreValues.filter(
          (t) => Number(t.rfq_id) === Number(rfqId)
        );
      if (
        open &&
        rfqRes.length !== 0 &&
        rfqRes[0]?.split_values &&
        rfqRes[0]?.split_values?.length > 0
      ) {
        dispatch(
          rfqPostTradeSlice.setPostTradeSplitNumber({
            key: "postTradeSplitNumber",
            value: rfqRes[0].split_number,
          })
        );
        if (rfqRes[0].split_manner === "percentage") {
          setSplitManner(rfqRes[0].split_manner);
          dispatch(rfqPostTradeSlice.setSplitManner(rfqRes[0].split_manner));
          setSplitManner(rfqRes[0].split_manner);
          for (const split of rfqRes[0].split_values) {
            const clientObj = {
              value: split.postTradeClientSplitId,
              key: "postTradeClientSplitId",
              id: split.id,
            };
            const pmObj = {
              value: split.postTradePmSplitId
                ? split.postTradePmSplitId
                : "no options",
              key: "postTradePmSplitId",
              id: split.id,
            };
            const optionObj = {
              value: split.postTradeSplitOption,
              key: "postTradeSplitOption",
              id: split.id,
            };
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj));
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj));
          }
        } else if (rfqRes[0].split_manner === "notional") {
          setSplitManner(rfqRes[0].split_manner);
          dispatch(rfqPostTradeSlice.setSplitManner(rfqRes[0].split_manner));
          if (DEAL_TYPE !== "SWAP") {
            for (const split of rfqRes[0].split_values) {
              for (const [key, value] of Object.entries(split)) {
                if (key.includes("Delta") || key.includes("Leg")) {
                  let optionObj = {
                    value: split[key],
                    key: key,
                    id: split.id,
                  };
                  dispatch(
                    rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj)
                  );
                }
                const clientObj = {
                  value: split.postTradeClientSplitId,
                  key: "postTradeClientSplitId",
                  id: split.id,
                };
                const pmObj = {
                  value: split.postTradePmSplitId
                    ? split.postTradePmSplitId
                    : "no options",
                  key: "postTradePmSplitId",
                  id: split.id,
                };
                dispatch(
                  rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj)
                );
                dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
              }
            }
          } else {
            for (const split of rfqRes[0].split_values) {
              for (const [key, value] of Object.entries(split)) {
                if (key.includes("near") || key.includes("far")) {
                  let optionObj = {
                    value: split[key],
                    key: key,
                    id: split.id,
                  };
                  dispatch(
                    rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj)
                  );
                }
                const clientObj = {
                  value: split.postTradeClientSplitId,
                  key: "postTradeClientSplitId",
                  id: split.id,
                };
                const pmObj = {
                  value: split.postTradePmSplitId
                    ? split.postTradePmSplitId
                    : "no options",
                  key: "postTradePmSplitId",
                  id: split.id,
                };
                dispatch(
                  rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj)
                );
                dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
              }
            }
          }
        }
      } else {
        setSplitManner("percentage");
        dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
      }
    } else if (open && rfq.length === 0) {
      const obj = {
        value: 2,
        key: "postTradeSplitNumber",
      };
      dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
      dispatch(rfqPostTradeSlice.resetPostTradeStats());
    }
  }, [open]);

  const handleSubmit = async () => {
    const fieldsValidatorArr = [];
    // ! first Validation for % splits
    if (splitManner === "percentage") {
      splitNumArr.map((obj, index) => {
        if (
          !postTradeSplitArrValues ||
          !postTradeSplitArrValues[index]?.postTradeClientSplitId ||
          postTradeSplitArrValues[index]?.postTradeClientSplitId === ""
        ) {
          fieldsValidatorArr.push({
            status: false,
            message: `Client field in row ${index + 1} is mandatory`,
          });
        } else if (
          !postTradeSplitArrValues ||
          !postTradeSplitArrValues[index]?.postTradeSplitOption ||
          postTradeSplitArrValues[index]?.postTradeSplitOption === ""
        ) {
          fieldsValidatorArr.push({
            status: false,
            message: `Allocation field in row ${index + 1} is mandatory`,
          });
        }
        if (
          !postTradeSplitArrValues[index]?.postTradePmSplitId ||
          postTradeSplitArrValues[index]?.postTradePmSplitId === ""
        ) {
          const obj = {
            value: "no options",
            key: "postTradePmSplitId",
            id: index,
          };
          dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(obj));
          // dispatch(rfqPostTradeSlice.setCurrentPb(value));
        }
        // ! validate the Pm , pm is not mandatory.
        if (
          !postTradeSplitArrValues[index]?.postTradePmSplitId ||
          postTradeSplitArrValues[index]?.postTradePmSplitId === ""
        ) {
          const obj = {
            value: "no options",
            key: "postTradePmSplitId",
            id: index,
          };
          // ! dispatch only for the PM inputs.
          dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(obj));
          // dispatch(rfqPostTradeSlice.setCurrentPb(value));
        }
      });
    } else if (splitManner === "notional") {
      if (DEAL_TYPE !== "SWAP") {
        postTradeAmountLeftToNotionalSplit.map((obj, index) => {
          if (
            !postTradeSplitArrValues ||
            !postTradeSplitArrValues[index]?.postTradeClientSplitId ||
            postTradeSplitArrValues[index]?.postTradeClientSplitId === ""
          ) {
            fieldsValidatorArr.push({
              status: false,
              message: `Client field in split ${index + 1} is mandatory`,
            });
          } else {
            if (
              postTradeSplitArrValues[index].hasOwnProperty(
                `Leg${index + 1}NotionalSplitOption`
              )
            ) {
              if (
                postTradeSplitArrValues[index][
                  `Leg${index + 1}NotionalSplitOption`
                ] === ""
              ) {
                fieldsValidatorArr.push({
                  status: false,
                  message: `Leg ${index + 1} in split ${
                    index + 1
                  } is mandatory`,
                });
              }
            } else if (
              !postTradeSplitArrValues[index].hasOwnProperty(
                `Leg${index + 1}NotionalSplitOption`
              )
            ) {
              fieldsValidatorArr.push({
                status: false,
                message: `Leg ${index + 1} in split ${index + 1} is mandatory`,
              });
            }
          }
          postTradeAmountLeftToDeltaNotionalSplit?.forEach((value, index) => {
            if (Math.abs(value) > 0) {
              fieldsValidatorArr.push({
                status: false,
                message: `Delta ${index + 1} in split ${
                  index + 1
                } have ${Math.abs(value)} left To Split`,
              });
            }
            if (
              postTradeSplitArrValues[index]?.hasOwnProperty(
                `Delta${index + 1}NotionalSplitOption`
              )
            ) {
              if (
                postTradeSplitArrValues[index][
                  `Delta${index + 1}NotionalSplitOption`
                ] === ""
              ) {
                fieldsValidatorArr.push({
                  status: false,
                  message: `Delta ${index + 1} in split ${
                    index + 1
                  } is mandatory`,
                });
              }
            } else if (
              !postTradeSplitArrValues[index].hasOwnProperty(
                `Delta${index + 1}NotionalSplitOption`
              )
            ) {
              fieldsValidatorArr.push({
                status: false,
                message: `Delta ${index + 1} in split ${
                  index + 1
                } is mandatory`,
              });
            }
          });
          splitNumArr.forEach((split, index) => {
            // ! validate the Pm , pm is not mandatory.
            if (
              !postTradeSplitArrValues[index]?.postTradePmSplitId ||
              postTradeSplitArrValues[index]?.postTradePmSplitId === ""
            ) {
              const obj = {
                value: "no options",
                key: "postTradePmSplitId",
                id: index,
              };
              // ! dispatch only for the PM inputs.
              dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(obj));
              // dispatch(rfqPostTradeSlice.setCurrentPb(value));
            }
          });
        });
      } else if (DEAL_TYPE === "SWAP") {
        splitNumArr.map((obj, index) => {
          if (
            !postTradeSplitArrValues ||
            !postTradeSplitArrValues[index]?.postTradeClientSplitId ||
            postTradeSplitArrValues[index]?.postTradeClientSplitId === ""
          ) {
            fieldsValidatorArr.push({
              status: false,
              message: `Client field in row ${index + 1} is mandatory`,
            });
          } else if (postTradeNearNotionalLeftToSplit > 0) {
            fieldsValidatorArr.push({
              status: false,
              message: `There is ${postTradeNearNotionalLeftToSplit} Near Notional Left To Split`,
            });
          } else if (postTradeFarNotionalLeftToSplit > 0) {
            fieldsValidatorArr.push({
              status: false,
              message: `There is ${postTradeFarNotionalLeftToSplit} Far Notional Left To Split`,
            });
          }
          if (
            !postTradeSplitArrValues[index]?.postTradePmSplitId ||
            postTradeSplitArrValues[index]?.postTradePmSplitId === ""
          ) {
            const obj = {
              value: "no options",
              key: "postTradePmSplitId",
              id: index,
            };
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(obj));
            // dispatch(rfqPostTradeSlice.setCurrentPb(value));
          }
          // ! validate the Pm , pm is not mandatory.
          if (
            !postTradeSplitArrValues[index]?.postTradePmSplitId ||
            postTradeSplitArrValues[index]?.postTradePmSplitId === ""
          ) {
            const obj = {
              value: "no options",
              key: "postTradePmSplitId",
              id: index,
            };
            // ! dispatch only for the PM inputs.
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(obj));
            // dispatch(rfqPostTradeSlice.setCurrentPb(value));
          }
        });
      }
    }

    // ! if allocation % are left to put show error
    if (postTradeAmountLeftToSplit !== 0 && splitManner === "percentage") {
      fieldsValidatorArr.push({
        status: false,
        message: `Allocations are not adding up to 100% ,remaining percentage is: ${postTradeAmountLeftToSplit}`,
      });
    }
    if (splitManner === "notional") {
      postTradeAmountLeftToNotionalSplit.forEach((value, index) => {
        if (value > 0 && !isNaN(value)) {
          fieldsValidatorArr.push({
            status: false,
            message: `Left ${value} notional to add to Leg${index + 1}`,
          });
        }
      });
      postTradeAmountLeftToDeltaNotionalSplit.forEach((value, index) => {
        if (value > 0 && !isNaN(value)) {
          fieldsValidatorArr.push({
            status: false,
            message: `Left ${value} notional to add to Delta${index + 1}`,
          });
        }
      });
    }
    if (fieldsValidatorArr.length !== 0) {
      // ! if there are errors show the,
      dispatch(
        actionSnackBar.setSnackBar("error", fieldsValidatorArr[0].message, 3000)
      );
    } else if (fieldsValidatorArr.length === 0) {
      // ! if there are NO error
      const values = postTradeSplitArrValues.map((val) => {
        if (!val.postTradePmSplitId) {
          return { ...val, postTradePmSplitId: "no options" };
        } else return val;
      });
      const obj1 = {
        rfq_id: rfqId,
        key: "split_number",
        value: postTradeSplitNumber,
      };
      const obj2 = {
        rfq_id: rfqId,
        key: "split_values",
        value: values,
      };
      const obj3 = {
        rfq_id: rfqId,
        key: "split_manner",
        value: splitManner,
      };
      setSplitted(true);
      dispatch(rfqPostTradeSlice.setIsSplitted(true));
      dispatch(rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk(obj1));
      dispatch(rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk(obj2));
      dispatch(rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk(obj3));
      dispatch(rfqPostTradeSlice.setClientsWhenSplited());
      dispatch(
        rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
          rfq_id: rfqId,
          key: "currentPb",
          value: "",
        })
      );
      const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];

      const clients = values.map(
        (v, index) => `${v.postTradeClientSplitId} (${alphabet[index]})`
      );
      const oneClientFromSplit = values[0].postTradeClientSplitId;

      let pba;
      if (currentMarketMaker === "MAKOR-HSBC") {
        pba = allMakorHSBCClientAndMarketMakers.filter(
          (client) => client.name === oneClientFromSplit
        )[0].pba;
      } else if (currentMarketMaker === "MAKOR-RBS") {
        if (sd === true) {
          pba = oscarGrussClients.filter(
            (client) => client.name === oneClientFromSplit
          )[0].pba;
        } else {
          pba = makorClients.filter(
            (client) => client.name === oneClientFromSplit
          )[0].pba;
        }
      } else if (currentMarketMaker === "MAKOR-SCB") {
        pba = makorSCBClients.filter(
          (client) => client.name === oneClientFromSplit
        )[0].pba;
      } else {
        pba = oscarGrussClients.filter(
          (client) => client.name === oneClientFromSplit
        )[0].pba;
      }
      dispatch(
        rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
          rfq_id: rfq_object.rfq_id,
          key: "currentClient",
          value: clients,
        })
      );
      dispatch(rfqPostTradeSlice.setPBA(pba));
      handleClose();
    }
  };

  const handleRemoveSplit = () => {
    const obj = {
      value: postTradeSplitNumber === 2 ? 2 : postTradeSplitNumber - 1,
      key: "postTradeSplitNumber",
    };
    dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
  };

  const arrangeNotionalFields = () => {
    if (rfq_object !== null && rfq_object !== undefined) {
      if (DEAL_TYPE !== "SWAP") {
        let numberOfLegs = Object?.keys(rfq_object?.rfq_table_data[0])?.slice(
          1
        );
        setNumberOfLegs(numberOfLegs);
        const HEDGE_MATCH_TYPE = rfq_object?.rfq_table_data.find(
          (field) => field[0] === "HEDGE_MATCH_TYPE"
        )[1][0];

        const HEDGE_VAL = rfq_object?.rfq_table_data.find(
          (field) => field[0] === "HEDGE_VAL"
        );

        const CLIENT_HEDGE_VAL = rfq_object?.rfq_table_data.find(
          (field) => field[0] === "CLIENT_HEDGE_VAL"
        );

        const HEDGE_DELTA = rfq_object?.rfq_table_data.find(
          (field) => field[0] === "HEDGE_DELTA"
        );

        const CLIENT_HEDGE_DELTA = rfq_object?.rfq_table_data.find(
          (field) => field[0] === "CLIENT_HEDGE_DELTA"
        );

        let rfqDeltasNotional;
        let notionalValues = rfq_object?.rfq_table_data.find(
          (field) => field[0] === "NOTIONAL"
        );

        notionalValues = Object.values(notionalValues)
          .filter(Array.isArray)
          .flat()
          .map(Number);

        dispatch(rfqPostTradeSlice.setCurrentLegsNotional(notionalValues));

        let deltaNotional;
        if (HEDGE_MATCH_TYPE === "MATCHED") {
          deltaNotional = Object.entries(HEDGE_VAL)
            .map(([key, value]) => {
              if (key !== 0) {
                if (
                  Array.isArray(value) &&
                  value.length > 0 &&
                  value[0] !== "" &&
                  value[0] !== null
                ) {
                  return `Delta ${key}`;
                }
              }
            })
            .slice(1)
            .filter((delta) => delta !== undefined);
          rfqDeltasNotional = Object.values(HEDGE_DELTA)
            .filter(Array.isArray)
            .flat()
            .map(Number);
        } else if (HEDGE_MATCH_TYPE === "UNMATCHED") {
          deltaNotional = Object.entries(CLIENT_HEDGE_VAL)
            .map(([key, value]) => {
              if (key !== 0) {
                if (
                  Array.isArray(value) &&
                  value.length > 0 &&
                  value[0] !== "" &&
                  value[0] !== null
                ) {
                  return `Delta ${key}`;
                }
              }
            })
            .slice(1)
            .filter((delta) => delta !== undefined);
          rfqDeltasNotional = Object.values(CLIENT_HEDGE_DELTA)
            .filter(Array.isArray)
            .flat()
            .map(Number);
        }
        rfqDeltasNotional = deltaNotional.map((deltaNotional, index) => {
          return rfqDeltasNotional[index];
        });
        setRfqDeltas(rfqDeltasNotional);

        let currentDeltaNotionalValues = deltaNotional.map((delta, index) => {
          return rfqDeltasNotional[index];
        });
        dispatch(
          rfqPostTradeSlice.setCurrentDeltasNotional(currentDeltaNotionalValues)
        );
        setNumberOfDeltas(deltaNotional);
      } else if (DEAL_TYPE === "SWAP") {
        let NEAR_NOTIONAL = rfq_object?.rfq_table_data.find(
          (field) => field[0] === "NEAR_NOTIONAL"
        );
        NEAR_NOTIONAL = Object.values(NEAR_NOTIONAL)
          .filter(Array.isArray)
          .flat()
          .map(Number);
        let FAR_NOTIONAL = rfq_object?.rfq_table_data.find(
          (field) => field[0] === "FAR_NOTIONAL"
        );
        FAR_NOTIONAL = Object.values(FAR_NOTIONAL)
          .filter(Array.isArray)
          .flat()
          .map(Number);
        dispatch(rfqPostTradeSlice.setCurrentNearNotional(NEAR_NOTIONAL[0]));
        dispatch(rfqPostTradeSlice.setCurrentFarNotional(FAR_NOTIONAL[0]));
      }
      if (postTradeSplitArrValues.length === 0) {
        const obj = {
          value: 2,
          key: "postTradeSplitNumber",
        };
        dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
        dispatch(rfqPostTradeSlice.resetPostTradeStats());
        dispatch(rfqPostTradeSlice.removeSplitFromClients());
      }
    }
  };

  const handleSplitMannerChange = (clickedSplitManner) => {
    if (clickedSplitManner === "percentage") {
      console.log(rfq_object);
      setSplitManner(clickedSplitManner);
      dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
    } else {
      setSplitManner(clickedSplitManner);
      dispatch(rfqPostTradeSlice.setSplitManner("notional"));
      arrangeNotionalFields();
    }
    const currentPreValues = postTradeSplitArrPreValues?.filter(
      (t) => Number(t.rfq_id) === Number(sessionStorage.getItem("rfqId"))
    );
    if (currentPreValues[0].split_manner !== clickedSplitManner) {
      const obj = {
        value: 2,
        key: "postTradeSplitNumber",
      };
      dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
      dispatch(rfqPostTradeSlice.resetPostTradeStats());
      dispatch(rfqPostTradeSlice.removeSplitFromClients());
    } else {
      if (currentPreValues[0].split_manner === "percentage") {
        setSplitManner(currentPreValues[0].split_manner);
        dispatch(
          rfqPostTradeSlice.setSplitManner(currentPreValues[0].split_manner)
        );
        setSplitManner(currentPreValues[0].split_manner);
        for (const split of currentPreValues[0].split_values) {
          const clientObj = {
            value: split.postTradeClientSplitId,
            key: "postTradeClientSplitId",
            id: split.id,
          };
          const pmObj = {
            value: split.postTradePmSplitId
              ? split.postTradePmSplitId
              : "no options",
            key: "postTradePmSplitId",
            id: split.id,
          };
          const optionObj = {
            value: split.postTradeSplitOption,
            key: "postTradeSplitOption",
            id: split.id,
          };
          dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj));
          dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
          dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj));
        }
      } else if (currentPreValues[0].split_manner === "notional") {
        setSplitManner(currentPreValues[0].split_manner);
        dispatch(
          rfqPostTradeSlice.setSplitManner(currentPreValues[0].split_manner)
        );
        if (DEAL_TYPE !== "SWAP") {
          for (const split of currentPreValues[0].split_values) {
            for (const [key, value] of Object.entries(split)) {
              if (key.includes("Delta") || key.includes("Leg")) {
                let optionObj = {
                  value: split[key],
                  key: key,
                  id: split.id,
                };
                dispatch(
                  rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj)
                );
              }
              const clientObj = {
                value: split.postTradeClientSplitId,
                key: "postTradeClientSplitId",
                id: split.id,
              };
              const pmObj = {
                value: split.postTradePmSplitId
                  ? split.postTradePmSplitId
                  : "no options",
                key: "postTradePmSplitId",
                id: split.id,
              };
              dispatch(
                rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj)
              );
              dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
            }
          }
        } else {
          for (const split of currentPreValues[0].split_values) {
            for (const [key, value] of Object.entries(split)) {
              if (key.includes("near") || key.includes("far")) {
                let optionObj = {
                  value: split[key],
                  key: key,
                  id: split.id,
                };
                dispatch(
                  rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj)
                );
              }
              const clientObj = {
                value: split.postTradeClientSplitId,
                key: "postTradeClientSplitId",
                id: split.id,
              };
              const pmObj = {
                value: split.postTradePmSplitId
                  ? split.postTradePmSplitId
                  : "no options",
                key: "postTradePmSplitId",
                id: split.id,
              };
              dispatch(
                rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj)
              );
              dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (postTradeSplitArrValues.length > 0) {
      if (
        postTradeSplitArrValues[0].hasOwnProperty("Leg1NotionalSplitOption")
      ) {
        setSplitManner("notional");
        dispatch(rfqPostTradeSlice.setSplitManner("notional"));
        arrangeNotionalFields();
      } else if (postTradeSplitArrValues[0].hasOwnProperty("nearNotional")) {
        setSplitManner("notional");
        dispatch(rfqPostTradeSlice.setSplitManner("notional"));
      } else {
        setSplitManner("percentage");
        dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
      }
    }
  }, [open]);

  useEffect(() => {
    const currentRfq = rfqList.filter(
      (r) => Number(r.rfq_id) === Number(rfqId)
    );
    let currentRfqFromPre = postTradeSplitArrPreValues?.filter(
      (rfq) => +rfq?.rfq_id === currentRfq[0]?.rfq_id
    );
    if (currentRfqFromPre?.length > 0) {
      dispatch(
        rfqPostTradeSlice.setSingleConfo(currentRfqFromPre[0]?.singleConfo)
      );
      if (
        currentRfqFromPre[0]?.split_values[0]?.hasOwnProperty(
          "Leg1NotionalSplitOption"
        )
      ) {
        setSplitManner("notional");
        dispatch(rfqPostTradeSlice.setSplitManner("notional"));
        arrangeNotionalFields();
        for (const split of currentRfqFromPre[0]?.split_values) {
          for (const [key, value] of Object.entries(split)) {
            if (key.includes("Delta") || key.includes("Leg")) {
              let optionObj = {
                value: split[key],
                key: key,
                id: split.id,
              };
              dispatch(
                rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj)
              );
            }
            const clientObj = {
              value: split.postTradeClientSplitId,
              key: "postTradeClientSplitId",
              id: split.id,
            };
            const pmObj = {
              value: split.postTradePmSplitId
                ? split.postTradePmSplitId
                : "no options",
              key: "postTradePmSplitId",
              id: split.id,
            };
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj));
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
          }
        }
      } else if (
        currentRfqFromPre[0]?.split_values[0]?.hasOwnProperty("nearNotional")
      ) {
        setSplitManner("notional");
        dispatch(rfqPostTradeSlice.setSplitManner("notional"));
        for (const split of currentRfqFromPre[0]?.split_values) {
          for (const [key, value] of Object.entries(split)) {
            if (key.includes("near") || key.includes("far")) {
              let optionObj = {
                value: split[key],
                key: key,
                id: split.id,
              };
              dispatch(
                rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj)
              );
            }
            const clientObj = {
              value: split.postTradeClientSplitId,
              key: "postTradeClientSplitId",
              id: split.id,
            };
            const pmObj = {
              value: split.postTradePmSplitId
                ? split.postTradePmSplitId
                : "no options",
              key: "postTradePmSplitId",
              id: split.id,
            };
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj));
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
          }
        }
      } else {
        setSplitManner("percentage");
        dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
        dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
        setSplitManner(currentRfqFromPre[0]?.split_manner);
        for (const split of currentRfqFromPre[0]?.split_values) {
          const clientObj = {
            value: split.postTradeClientSplitId,
            key: "postTradeClientSplitId",
            id: split.id,
          };
          const pmObj = {
            value: split.postTradePmSplitId
              ? split.postTradePmSplitId
              : "no options",
            key: "postTradePmSplitId",
            id: split.id,
          };
          const optionObj = {
            value: split.postTradeSplitOption,
            key: "postTradeSplitOption",
            id: split.id,
          };
          dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj));
          dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
          dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj));
        }
      }
    } else {
      setSplitManner("percentage");
      dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={() => {
        const res =
          open && rfqList.filter((r) => Number(r.rfq_id) === Number(rfqId));
        const currentPreValues = postTradeSplitArrPreValues?.filter(
          (t) => Number(t.rfq_id) === Number(sessionStorage.getItem("rfqId"))
        );
        if (
          (res[0]?.status !== "Booked" && confirmed !== true) &&
          currentPreValues.length === 0
        ) {
          const obj = {
            value: 2,
            key: "postTradeSplitNumber",
          };
          dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
          dispatch(rfqPostTradeSlice.resetPostTradeStats());

          handleClose();
        } else {
          handleClose();
        }
      }}
      classes={{ paper: classes.dialogPaperRfq }}
      PaperProps={{
        style: {
          backgroundColor: "#2D2D2D",
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "30px 84px 32px 75px", position: "absolute" }}
      >
        <Grid item xs={11}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Typography style={{ fontSize: "1.5rem", color: "#ffffff" }}>
                Post trade split
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <IconButton
                size="small"
                onClick={() => {
                  const res =
                    open &&
                    rfqList.filter((r) => Number(r.rfq_id) === Number(rfqId));
                  const currentPreValues = postTradeSplitArrPreValues?.filter(
                    (t) =>
                      Number(t.rfq_id) ===
                      Number(sessionStorage.getItem("rfqId"))
                  );
                  console.log("currentPreValues: ", currentPreValues);
                  if (
                    (res[0]?.status !== "Booked" && confirmed !== true) &&
                    currentPreValues.length === 0
                  ) {
                    const obj = {
                      value: 2,
                      key: "postTradeSplitNumber",
                    };
                    dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
                    dispatch(rfqPostTradeSlice.resetPostTradeStats());
                    handleClose();
                  } else {
                    handleClose();
                  }
                }}
                style={{
                  backgroundColor: "#3a3c3f",
                  left: "125px",
                  top: "-20px",
                }}
              >
                <CloseIcon
                  style={{ color: "#bababa", fontSize: "1.1875rem" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <SelectOption values={splitNumObj} splitManner={splitManner} />
          </Grid>
          <Grid item container xs={5} justifyContent="center">
            <ButtonGroup
              className={classes.btnGroup}
              style={{ position: "absolute", top: "80px", height: "31px" }}
              disabled={confirmed || props.isBooked}
            >
              <Button
                className={clsx(classes.btnInside, {
                  [classes.chosenBtnInside]: splitManner === "percentage",
                })}
                onClick={() => {
                  handleSplitMannerChange("percentage");
                }}
              >
                %
              </Button>
              <Button
                className={clsx(classes.btnInside, {
                  [classes.chosenBtnInside]: splitManner === "notional",
                })}
                onClick={() => {
                  handleSplitMannerChange("notional");
                }}
              >
                Notional
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        {/* {splitManner === "notional" && DEAL_TYPE !== "SWAP" && ( 
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: "10px" }}
          >
            <Grid item xs={2}>
              <Typography style={{ color: "#13b5ba", fontSize: "1.125rem" }}>
                Current Deltas:
              </Typography>
            </Grid>
            {rfqDeltas &&
              rfqDeltas.map((delta, index) => {
                return (
                  <Grid item xs={2}>
                    <Typography style={{ color: "#ffffff" }}>
                      {`Delta Leg ${index + 1}:`}
                      <b> {`${delta}`}</b>
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        )} */}
        <Grid item container>
          <Divider variant="middle" className={classes.dividerPostTrades} />
        </Grid>
        {splitManner === "percentage" ? (
          <Grid
            container
            justifyContent="space-between"
            xs={12}
            style={{
              paddingTop: "20px",
              marginTop: "40px",
              maxHeight: "380px",
              overflow: "auto",
            }}
          >
            {splitNumArr.map((obj, index) => {
              let objPm;
              const id = index;
              clientsListObj.id = id;
              const newClientObj = { ...clientsListObj, type };
              splitOptionObj.id = id;
              const newSplitOptionObj = { ...splitOptionObj };

              if (
                postTradesSplitArrPmValues.length !== 0 &&
                postTradesSplitArrPmValues[`${index}`] !== undefined &&
                postTradesSplitArrPmValues[`${index}`]
                  .postTradeClientSplitId[0] !== null
              ) {
                objPm = objPm = {
                  label: "PM",
                  values: postTradesSplitArrPmValues[
                    `${index}`
                  ].postTradeClientSplitId.map((pm) => {
                    return { id: pm, value: pm };
                  }),
                  key: "postTradePmSplitId",
                  id,
                  type,
                };
              } else {
                objPm = {
                  label: "PM",
                  values: [
                    {
                      id: splitPmArrInit[`${index}`].id,

                      value: splitPmArrInit[`${index}`].value,
                    },
                  ],
                  key: "postTradePmSplitId",
                  id,
                  type,
                };
              }

              return (
                <Grid
                  key={id}
                  item={true}
                  container
                  xs={12}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Grid item xs={4}>
                    <SelectOption
                      values={newClientObj}
                      key={id}
                      splitManner={splitManner}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectOption
                      values={objPm}
                      key={id}
                      splitManner={splitManner}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberInput
                      values={newSplitOptionObj}
                      key={id}
                      className={"numberSplit"}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            style={{
              paddingTop: "0px",
              marginTop: "15px",
              overflow: "auto",
              maxHeight: "400px",
              minWidth: "757px",
            }}
          >
            {splitNumArr.map((obj, mainIndex) => {
              let objPm;
              const id = mainIndex;
              clientsListObj.id = id;
              const newClientObj = { ...clientsListObj, type };
              splitOptionObj.id = id;
              const newSplitOptionObj = { ...splitOptionObj };

              if (
                postTradesSplitArrPmValues.length !== 0 &&
                postTradesSplitArrPmValues[`${mainIndex}`] !== undefined &&
                postTradesSplitArrPmValues[`${mainIndex}`]
                  .postTradeClientSplitId[0] !== null
              ) {
                objPm = objPm = {
                  label: "PM",
                  values: postTradesSplitArrPmValues[
                    `${mainIndex}`
                  ].postTradeClientSplitId.map((pm) => {
                    return { id: pm, value: pm };
                  }),
                  key: "postTradePmSplitId",
                  id,
                  type,
                };
              } else {
                objPm = {
                  label: "PM",
                  values: [
                    {
                      id: splitPmArrInit[`${mainIndex}`].id,

                      value: splitPmArrInit[`${mainIndex}`].value,
                    },
                  ],
                  key: "postTradePmSplitId",
                  id,
                  type,
                };
              }
              return (
                <Grid
                  key={mainIndex}
                  item
                  container
                  style={{
                    borderBottom: "1px solid #767c8e",
                    paddingBottom: "20px",
                    gap: "1rem",
                  }}
                  justifyContent="flex-start"
                >
                  <Grid item xs={12}>
                    <Typography style={{ color: "#ffffff" }}>
                      {`Client ${mainIndex + 1}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SelectOption
                      values={newClientObj}
                      key={id}
                      splitManner={splitManner}
                    />
                  </Grid>
                  <Grid item>
                    <SelectOption
                      values={objPm}
                      key={id}
                      splitManner={splitManner}
                    />
                  </Grid>
                  {DEAL_TYPE !== "SWAP" ? (
                    <>
                      {numberOfLegs?.map((legNumber, index) => {
                        return (
                          <Grid Item key={index}>
                            <NumberInput
                              values={{
                                id: mainIndex,
                                label: `Leg ${legNumber} Notional`,
                                key: `Leg${legNumber}NotionalSplitOption`,
                              }}
                              key={id}
                              className={"numberSplit"}
                            />
                          </Grid>
                        );
                      })}
                      {numberOfDeltas?.map((delta, index) => {
                        return (
                          <Grid item>
                            <NumberInput
                              values={{
                                id: mainIndex,
                                label: `${delta} Notional`,
                                key: `${delta
                                  .split(" ")
                                  .join("")}NotionalSplitOption`,
                              }}
                              key={id}
                              className={"numberSplit"}
                            />
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <Grid item>
                        <NumberInput
                          values={{
                            id: mainIndex,
                            label: `Near Notional`,
                            key: `nearNotional`,
                          }}
                          key={id}
                          className={"numberSplit"}
                        />
                      </Grid>
                      <Grid item>
                        <NumberInput
                          values={{
                            id: mainIndex,
                            label: `Far Notional`,
                            key: `farNotional`,
                          }}
                          key={id}
                          className={"numberSplit"}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        xs={12}
        alignItems="flex-end"
        style={{
          position: "absolute",
          bottom: "50px",
          backgroundColor: "transparent",
          width: "470px",
          marginLeft: "200px",
        }}
      >
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.removeSplitBtn}
            onClick={handleRemoveSplit}
          >
            Remove Split
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            className={classes.postTradeBtn}
            onClick={() => {
              const res =
                open &&
                rfqList.filter((r) => Number(r.rfq_id) === Number(rfqId));
              const currentPreValues = postTradeSplitArrPreValues?.filter(
                (t) =>
                  Number(t.rfq_id) === Number(sessionStorage.getItem("rfqId"))
              );
              if (
                res[0]?.status !== "Booked" &&
                res[0]?.status !== "Confirmed" &&
                res[0]?.status !== "Confirmed & Booked" &&
                currentPreValues.length === 0
              ) {
                const obj = {
                  value: 2,
                  key: "postTradeSplitNumber",
                };
                dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
                dispatch(rfqPostTradeSlice.resetPostTradeStats());
                dispatch(rfqPostTradeSlice.removeSplitFromClients());
                handleClose();
              } else {
                handleClose();
              }
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            className={classes.postTradeBtn}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
